@import '~scss/imports';

.Things__heading {
  @extend %font-h2-sans-bold;

  text-align: center;
  margin-bottom: 1em;
  margin-top: 0;

  &:empty {
    margin: 0;
    padding: 0;
  }
}

.Things {
  list-style-type: none;
  margin: 0;
  padding: 0;

  @include breakpoint('tablet') {
    display: grid;
    grid-auto-columns: 1fr;
    grid-gap: 1em;

    .Things-item {
      grid-row: 1;
    }
  }
}

.Things__container:last-child {
  margin-bottom: 2em;
}

.Things-item-heading {
  @extend %font-h3-sans;
}

.Things-item-text {
  @extend %font-body;

  p {
    max-width: 50em;
  }
}

.Things-item-links {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li + li {
    margin-top: 0.5em;
  }
}
.Things-item-link {
  @extend %cta-link;
  line-height: 2.5;
  border-bottom-color: var(--blue);
}

.Things-number {
  padding-left: 1rem;
  padding-right: 1rem;

  @include breakpoint('tablet') {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
}

.Things-number__number {
  @extend %font-h1-sans;

  font-size: 58px;
  margin-bottom: 1rem;
}
