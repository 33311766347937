@import '~scss/imports';
.Nav,
.Menu {
  z-index: 3;

  a {
    color: inherit;
    text-decoration: none;
  }
}
.Nav {
  pointer-events: none;
  .Nav-inner {
    pointer-events: auto;
  }
}
.Menu {
  @extend %font-h4-sans;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--blue);
  color: var(--white);
  fill: var(--white);

  .MenuClose {
    margin-left: auto;
    margin-right: 1rem;
    svg * {
      fill: inherit;
    }
  }
}
.Menu-inner {
  padding: 1rem;
  white-space: nowrap;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Nav {
  @extend %font-nav;
  display: flex;
  color: var(--blue);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  .MenuToggle {
    margin-right: 1rem;
  }
  // svg * {
  //   fill: inherit;
  // }
}

.Nav-menu {
  background-color: var(--white);
}

.App--white {
  .receives-theme {
    background-color: var(--white);
    fill: var(--blue);
  }
  .receives-opposing-theme {
    background-color: var(--blue);
  }
}

.App--lightBlue {
  .receives-theme {
    background-color: var(--light-blue);
    fill: var(--blue);
  }
  .receives-opposing-theme {
    background-color: var(--blue);
  }
}

.App--blue {
  // .Nav {
  //   svg * {
  //     fill: inherit;
  //   }
  // }
  .receives-theme {
    background-color: var(--blue);
    color: var(--white);
    fill: var(--white);
  }
  .receives-opposing-theme {
    background-color: var(--white);
  }
}

.Nav-inner {
  background-color: var(--white);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Nav-inner-pad {
  @extend %max;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.Nav-logo {
  padding: 0 1em;
  align-items: center;
  display: flex;
  max-width: 200px;

  svg {
    width: 100%;
  }

  @media (min-width: 600px) {
    max-width: 240px;
  }
}

.nav-item {
  align-items: center;
  display: flex;
  line-height: 1.25;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom-color: var(--yellow);
  }

  &.active {
    border-bottom-color: inherit;
  }
}

.Nav-toggle {
  margin-left: auto;
  background-color: none;
  border: 0;
  > div {
    width: 30px;
    height: 2px;
    background-color: var(--black);
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.Nav-toplinks {
  background-color: inherit;

  a {
    color: inherit;
    text-decoration: none;
  }

  .nav-item {
    margin: 0 0.75rem;
  }
}

.Nav-toplinks {
  display: flex;
  align-self: center;
  margin-left: auto;
  position: relative;
}

.Nav-subnav {
  text-transform: none;
  font-weight: 400;
  padding: 0;
  list-style-type: none;
  margin: unset;
  position: absolute;
  left: 0;
  min-width: 100%;
  top: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;

  li {
    margin: 0.25rem 0;
  }

  .nav-item {
    margin: unset;
    display: inline-block;
  }

  .active & {
    opacity: 1;
    pointer-events: all;
  }
  > * {
    flex-shrink: 0;
  }
}

.Nav-subnavlabel {
  color: inherit;
  font: inherit;
  background-color: transparent;
  text-transform: inherit;
  border: 0;
  border-radius: 0;
}

.MenuToggle,
.MenuClose {
  color: inherit;
  font: inherit;
  background-color: transparent;
  text-transform: inherit;
  border: 0;
  border-radius: 0;
  padding: 1rem;
  width: 2rem;
  height: 2rem;
  position: relative;
  outline: 0;

  div {
    width: 100%;
    left: 0;
    height: 0.25rem;
    position: absolute;
  }
}

.MenuToggle {
  div {
    top: 50%;
    background-color: var(--blue);
  }
  div:first-child {
    transform: translateY(-250%);
  }
  div:nth-child(2) {
    transform: translateY(-50%);
  }
  div:last-child {
    transform: translateY(150%);
  }
}
.MenuClose {
  //margin-left: auto;
  height: var(--nav-height);
  div {
    background-color: var(--white);
  }
  div:first-child {
    transform: rotate(45deg);
  }
  div:last-child {
    transform: rotate(-45deg);
  }
}

.ResourcesBar-inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint('tablet') {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    ul {
      flex-shrink: 0;
    }
  }

  background-color: var(--yellow);
  color: var(--black);
  @extend %max;
  text-transform: uppercase;
  @extend %font-cta;
  padding: 0.125rem 2rem 0.125rem;
  // margin-bottom: .5rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;

  a {
    color: inherit;
    text-decoration: none;
    line-height: 1.1;
    &:hover {
      color: var(--blue);
    }
    display: none;
    @media (min-width: 400px) {
      display: block;
    }
  }

  ul {
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    @media (min-width: 375px) {
      flex-direction: row;
    }
  }
  li + li {
    margin-left: 1em;
  }
  li a {
    align-items: center;
    display: flex;

    svg {
      margin-right: 0.5rem;
      * {
        fill: var(--black);
      }
    }
  }
  svg {
    display: block;
    width: 14px;
    height: auto;

    @media (min-width: 500px) {
      width: 18px;
    }
  }
}
