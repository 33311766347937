@import "~scss/imports";

.ExplainerLinks {
  display: grid;
  grid-gap: 4rem;

  @include breakpoint('tablet') {
    grid-template-columns: 1fr 1fr;
  }
}

.ExplainerLink {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
}

.ExplainerLink a {
  color: inherit;
  text-decoration: none;
  text-align: center;

  display: grid;
  grid-template-areas:
    "image"
    "heading"
    "description"
  ;
  grid-row-gap: 1rem;

  &:hover {
    .ExplainerLink-heading {
      color: var(--blue);
    }
  }

  @include breakpoint('tablet') {
    text-align: left;
    grid-template-areas:
      "image heading"
      "image description"
    ;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 1rem;
  }
}

.ExplainerLink-image {
  max-width: 100px;
  grid-area: image;
  margin: 0 auto;
}

.ExplainerLink-heading {
  grid-area: heading;
  @extend %font-h3-sans;
}

.ExplainerLink-description {
  grid-area: description;
  @extend %font-body;
}

