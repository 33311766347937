@import "~scss/imports";

.StackedBars {
  position: relative;
  display: flex;
  flex-direction: column;
  .Loading {
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.StackedBars-loader {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.75);
}

.StackedBars--loading {
  min-height: 5em;
  .StackedBars-loader {
    display: block;
  }
  .Loading {
    visibility: visible;
  }
}

.StackedBars-chartwrap {
  position: relative;
  height: 100%;

  min-height: 50vh;

  svg {
    position: absolute;
    height: 100%;
    width: auto;
    overflow: visible;
  }
  .CAT--desktop & {
    min-height: 0;
    padding-left: 0;
    // min-height: 500px;
  }
}

//.StackedBars-chartwrap--with-controls svg {
// height: calc(100% - 2rem);
//}
