.StandardHero {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  margin-top: var(--margin-vertical);
  margin-bottom: var(--margin-vertical);
  @extend %bigmax;
}

.StandardHero--default,
.StandardHero--halfLeft,
.StandardHero--halfRight {
  grid-template-areas:
    'image'
    'heading'
    'text'
    'link';
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, auto);
  grid-gap: 1rem;

  @include breakpoint('tablet') {
    > * {
      align-items: start;
    }
  }

  .StandardHero-heading {
    @extend %font-h1-serif;
    margin-top: 0;
    margin-bottom: 0;
  }
  .StandardHero-content {
    @extend %maxtext;
    @extend %marginauto;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
  }
}

.StandardHero--default,
.StandardHero--halfLeft {
  @include breakpoint('tablet') {
    grid-template-areas:
      'image .'
      'image .'
      'image heading'
      'image text'
      'image link'
      'image .'
      'image .';
    grid-gap: 1rem 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 5vw auto auto auto 5vw 1fr;
  }
}

.StandardHero--halfRight {
  @include breakpoint('tablet') {
    grid-template-areas:
      '. image'
      '. image'
      'heading image'
      'text image'
      'link image'
      '. image'
      '. image';
    grid-gap: 1rem 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 5vw auto auto auto 5vw 1fr;
  }
}

.StandardHero--fullImage {
  @extend %max;

  grid-template-areas:
    'image'
    'heading'
    'text';
  grid-gap: 2em;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;

  @include breakpoint('tablet') {
    grid-template-areas:
      'image image'
      'heading text';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
  }

  .StandardHero-heading {
    @extend %font-h1-serif;
  }
}
.StandardHero-heading,
.StandardHero-text,
.StandardHero-link {
  padding-left: 2rem;
  padding-right: 2rem;
}
.StandardHero-heading {
  grid-area: heading;
  margin: unset;
}
.StandardHero-text {
  grid-area: text;
  max-width: 30em;

  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
.StandardHero-image {
  grid-area: image;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.StandardHero-link {
  grid-area: link;
  padding-bottom: 2em;
}
.StandardHero-link a {
  @extend %cta-link;
  border-bottom-color: var(--blue);
}
.StandardHero-text {
  @extend %font-body;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
}

.People {
  $pad: 1rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;

  .People-item {
    width: 100%;
  }

  @media (min-width: 450px) {
    // grid-template-columns: repeat(2,1fr)
    .People-item {
      width: calc(50% - #{2 * $pad});
    }
  }
  @media (min-width: 700px) {
    .People-item {
      width: calc(33.33% - #{2 * $pad});
    }
    // grid-template-columns: repeat(3,1fr);
    // grid-template-rows: auto;
  }
  @media (min-width: 900px) {
    .People-item {
      width: calc(25% - #{2 * $pad});
    }
    // grid-template-columns: repeat(4,1fr);
    // grid-template-rows: auto;
  }
  @media (min-width: 1200px) {
    .People-item {
      width: calc(20% - #{2 * $pad});
    }
    // grid-template-columns: repeat(5,1fr);
    // grid-template-rows: auto;
  }
}
