@import 'fonts';
@import 'var';
@import 'reset';
@import 'type';
@import 'color';
@import 'layout';

@each $color in $colors {
  .color--#{$color} {
    color: var(--#{$color});
  }
  .bg--#{$color} {
    background-color: var(--#{$color});
  }
}
.bg--none {
  background-color: var(--white);
}
html {
  // scroll-behavior: smooth;
}
body {
  overflow-y: scroll;
}
a {
  color: inherit;
}
.App {
  display: grid;
  grid-template-areas:
    'resources resources'
    'main menu'
    'footer footer';
  grid-template-columns: 1fr auto;
}
.ResourcesBar {
  grid-area: resources;
  width: 100%;
}
.Nav {
  height: var(--nav-height);
  text-transform: uppercase;

  width: 100%;
}
.Menu {
  grid-area: menu;
}

.Nav-subnavlabel {
  z-index: 1;
  position: relative;
  background-color: var(--white);
}

.Nav-links {
  padding-left: 1rem;
}

.Nav-menu {
  position: fixed;
  top: var(--nav-height);
  z-index: 2;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  > div {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  ul {
    padding: 0;
  }
  li {
    display: block;
  }
  a {
    padding: 10px;
    color: inherit;
    text-decoration: none;
  }
}
.Main {
  grid-area: main;
  padding-top: var(--nav-height);
}

.SearchToggle {
  display: flex;
  color: inherit;
  text-decoration: unset;
}

.Footer {
  grid-area: footer;
  position: sticky;
  top: 0;
}

.full-height {
  min-height: 100vh;
}
.App--top .full-height {
  min-height: calc(100vh - 200px);
}
.layoutHeight--fullScreen {
  align-items: center;
  display: flex;
  @extend .full-height;
}
.max {
  @extend %max;
}
.bg--blue {
  background-color: var(--blue);
}
.PageLoader {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - var(--nav-height));
  animation-delay: 300ms;
  animation-duration: 300ms;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.rte {
  h1 {
    @extend %font-article-h1;
  }
  h2 {
    @extend %font-article-h2;
  }
  h3 {
    @extend %font-article-h3;
  }
  h4 {
    @extend %font-article-h4;
  }
  h5 {
    @extend %font-body;
  }
  h6 {
    @extend %font-bodysmall;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:first-child {
      margin-top: 0;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.media-container {
  position: relative;
  padding-top: 56.25%;

  img,
  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
