@import "~scss/imports";

.ReleaseAssessmentBlock {
  .CtaExplainer-link {
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }
  .Block-inner {
    cursor: pointer;

    &:hover .CtaExplainer-body {
      color: var(--blue);
    }
  }
}


.ReleaseAssessment {
  padding: 3rem 1rem 4rem;
}
.ReleaseAssessment-inner {
  @extend %max;
  width: 100%;
  @extend %bg--white;
  @extend %color--black;
  padding: 1rem;
  overflow: hidden;
  position: relative;
  border-radius: 1rem;

  @include breakpoint('tablet') {
    padding: 4rem;
  }
}
.ReleaseAssessment-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ReleaseAssessment-header-heading {
  @extend %font-h2-sans-bold;
  margin: 0;
}

.ReleaseAssessment-header-score {
  grid-area: score;
  text-align: right;
  dl { margin: 0 }
}
.ReleaseAssessment-header-score-label,
.ReleaseAssessment-score-label {
  // @extend %font-h4-sans-bold;
  // text-transform: uppercase;
  font-weight: bold;
}

.ReleaseAssessment-reset {
  background-color: transparent;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-radius: 0;
  cursor: pointer;
  @extend %cta-link;
  display: block;
  text-align: right;
  margin-top: 1em;
  margin-bottom: 1em;
}


.ReleaseAssessment-main {
  // @extend %blockmarginvertical;
  display: grid;
  grid-gap: 5vw;
  @include breakpoint('tablet') {
    grid-template-columns: 1fr 1fr;
  }
}
.ReleaseAssessment-questions {
  @extend %font-bodysmall;
  line-height: 1;
  table {
    td:last-child,
    th:last-child {
      text-align: right;
    }
    th {
      text-align: left;
    }
    th,td {
      padding: .75em 0;
    }
  }
  ol {
    padding-left: 1em;
  }
}

// .ReleaseAssessment-final {
//   display: grid;
//   grid-gap: 2rem;

//   @include breakpoint('tablet') {
//     grid-template-columns: 1fr 300px;
//     grid-gap: 4rem;
//   }
// }

.RATable {
  width: 100%;
  border-collapse: collapse;
  td,th {
    padding: 10px;
  }
  th {
    text-align: left;
  }
}

.ReleaseAssessment-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ReleaseAssessment-intro-heading {
  text-align: center;
  @extend %maxtext;
  @extend %marginauto;
  @extend %font-body;
}
.ReleaseAssessment-intro-table {
  td {
    border-top: 1px solid black;
  }
  td,th {
    &:first-child {
      width: 5em;
    }
  }
}
.ReleaseAssessment-intro-table-interviewed {
  align-items: center;
  display: flex;

  svg {
    display: none;
    @include breakpoint('tablet') {
      display: block;
    }
  }
}
.ReleaseAssessment-intro-table-interviewed-percentage {
  min-width: 2em;
  margin-right: 1em;
}
.ReleaseAssessment-intro-button {
  background-color: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  @extend %cta-link;
  margin-top: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.ReleaseAssessment-intro-table-recommendation {
  display: flex;
  align-items: center;

  & + .ReleaseAssessment-intro-table-recommendation {
    margin-top: .25em;
  }

  svg {
    margin-right: .25rem;
    flex-shrink: 0;
  }
}

.ReleaseAssessment-inactive-question {
  cursor: pointer;
  opacity: .5;
  @extend %linkhover;
  td {
    border-top: 1px solid rgba(0,0,0,.25);
  }
  &.is-answered {
    opacity: 1;
  }
  &.is-active {
    opacity: 1;

    .ReleaseAssessment-inactive-question-query {
      font-weight: bold;
      color: var(--blue);
    }
  }
}
.ReleaseAssessment-inactive-question-query {
  display: grid;
  grid-template-columns: 1em 1fr;
}

.ReleaseAssessment-inactive-question-points {
  margin-left: auto;
  flex-shrink: 0;
  text-transform: uppercase;
  font-weight: bold;
}

.ReleaseAssessment-question {
  display: grid;
  grid-gap: 1rem;
  padding-top: .45rem;
}
.ReleaseAssessment-question-heading {
  @extend %font-h3-sans-bold;
}
.ReleaseAssessment-question-description {
  @extend %font-body;
  text-align: left;

  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
.ReleaseAssessment-question-choices {
  display: grid;
  grid-gap: .5rem;
  grid-template-rows: auto 1fr;
}
.ReleaseAssessment-question-choices-item {
  @extend %font-body;
  // margin-top: 1em;

  cursor: pointer;
  @extend %linkhover;
  input[type="radio"] {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    & + div {
      // background-color: var(--light-blue);
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: .25em .25em .25em 1.25em;
      position: relative;

      &:before {
        content: '';
        width: 1em;
        height: 1em;
        position: absolute;
        left: 0;
        background-color: var(--light-blue);
      }
    }
    &:focus + div {
      outline: 2px solid var(--blue);
    }
    &:checked + div {
      &:after {
        content: "✓";
        color: var(--blue);
        left: .25em;
        position: absolute;
      }
      background-color: var(--light-blue);
    }
  }
}
.ReleaseAssessment-question-choices-item-points {
  font-size: 16px;
}

.ReleaseAssessment-result {
  display: grid;
  text-align: center;
  grid-gap: 1px;

  grid-template-areas:
    "score"
    "appearance"
    "recommendation"
  ;

  @media (min-width: 1000px) {
    grid-template-areas:
      "score appearance"
      "recommendation recommendation"
    ;
    grid-auto-columns: 1fr;
  }
  >* {
    box-shadow: 0 0 0 1px black;
  }
}
.ReleaseAssessment-result-score {
  grid-area: score;
}
.ReleaseAssessment-result-appearance {
  grid-area: appearance;
}
.ReleaseAssessment-result-recommendation {
  grid-area: recommendation;
}
.ReleaseAssessment-result-recommendation-list {
  display: grid;
  grid-auto-columns: 1fr;
  grid-gap: 1px;
  // height: 100%;

  .ReleaseAssessment-result-recommendation-item {
    grid-row: 1;
    box-shadow: 0 0 0 1px black;
    display: flex;
    flex-direction: column;
    // height: 100%;
  }
}
.ReleaseAssessment-result-recommendation-item-heading {
  height: 100%;
}
.ReleaseAssessment-result-recommendation-item-heading-outcome {
  font-weight: bold;
}
.ReleaseAssessment-result-recommendation-item-color {
  flex-shrink: 0;
  height: 1rem;
}
.ReleaseAssessment-result {
  // border: 1px solid;
}
.ResultBox {
  @extend %font-body;
  display: flex;
  flex-direction: column;
}
.ResultBox-heading {
  background-color: #f2f2f2;
  border-bottom: 1px solid;
  font-weight: bold;
}

.ReleaseAssessment-result-recommendation-item-heading,
.ResultBox-content {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
}
//Result Score
.ReleaseAssessment-result-score-value {
  @extend %font-h1-sans;
}
.ReleaseAssessment-result-score-label {
  @extend %font-bodysmall;
  font-weight: bold;
}

//Result Appearance Rate
.ReleaseAssessment-result-appearance-value {
  @extend %font-h2-sans-bold;
}
.ReleaseAssessment-result-appearance-label {
  @extend %font-bodysmall;
  font-weight: bold;
}

//Result Overall Recommendations
.OverallRecommendations {

}
.OverallRecommendations-heading {
  text-align: center;
  @extend %font-body;
  font-weight: bold;
}
.OverallRecommendations-item {
  margin-top: 1.5em;
}
.OverallRecommendations-item-heading {
  margin-top: .25rem;
}
.OverallRecommendations-item-heading-value {
  font-weight: bold;
  padding-right: .5em;
}

.ReleaseAssessment-total {
  justify-content: space-between;
  margin-top: 0;
  border-top: 1px solid rgba(0,0,0,.25);
  align-items: flex-end;
  text-align: right;
  display: flex;
}
.ReleaseAssessment-score {
  margin: 0;
  padding-top: .5rem;
}

.ReleaseAssessment-score-value {
  @extend %font-h2-sans-bold;
  line-height: 1;
  margin: 0;
}


.ReleaseAssessment-finalscore {
  text-align: center;
  margin-top: unset;
  margin-bottom: 78px;
}
.ReleaseAssessment-finalscore-label {
  @extend %font-callout;
}
.ReleaseAssessment-finalscore-value {
  margin: 0;
  @extend %font-callout-bold;
}

.ReleaseAssessment-recommendations {
  padding: 0;
  margin: 0;
  list-style-type: none;
  // display: flex;
}

.ReleaseAssessment-recommendations-item {
  width: 100%;
  padding: 1em;
}

.ReleaseAssessment-recommendations-item--active {
  @extend %bg--green;
  .ReleaseAssessment-recommendations-item-scores,
  .ReleaseAssessment-recommendations-item-points {
    font-weight: bold;
  }
}

.ReleaseAssessment-recommendations-item-scores {
  @extend %font-h3-sans;
  border-bottom: 1px solid;
}
.ReleaseAssessment-recommendations-item-points {
  padding-left: 1.3em;
  margin: 0;
  list-style-type: disc;
  li {
    margin-top: 1em;
  }
}
.ReleaseAssessment-recommendations-item-points-item {

}