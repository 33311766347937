.StaffPerson {
}

.StaffPerson-heading {
  @extend %font-h3-sans-bold;
  margin-bottom: 0;
}

.StaffPerson-jobTitle {
  margin-top: 0;
  @extend %font-body-bold;
}

.StaffPerson-text {
  @extend %font-body;
  margin-bottom: 1em;
}
