@import '~scss/imports';

.element {
  --toggle-height: calc(var(--nav-height) - 3rem);
  padding: 1rem 0;

  :global(.CAT--desktop) & {
    // height: 100%;
    // min-height: 560px;
    width: 100%;
    position: relative;

    .controls {
      position: absolute;
      width: var(--filter-width);
      height: 100%;
    }
    .results {
      height: 100%;
      padding-left: calc(var(--filter-width) + 2rem);
    }
  }
}

.globalControls {
  display: grid;
  grid-gap: 1rem 2rem;
  // padding: 1rem;
  // border: 1px solid var(--yellow);
  // border-radius: var(--border-radius);
}

.allFiltersReset {
  justify-self: flex-start;
  align-self: flex-end;
  margin: 0;
  @extend %toggleButtonLight;
}

.mobileAllFiltersReset {
  @extend %toggleButtonLight;
  margin-bottom: 1em;
  align-self: flex-start;
}

.elementWithControls {
  composes: element;
  display: grid;
  grid-gap: 2rem;
  grid-template-rows: auto 1fr;

  :global(.CAT--desktop) & {
    padding: 0;
    grid-template-rows: unset;
    // grid-template-columns: var(--dashboard-template-columns);
    // .controls {
    //   padding: 1rem;
    // }
  }
}

.controls {
  --pad: 1rem;
  position: fixed;
  height: 100vh;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--white);
  z-index: 999;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--light-blue);
  padding: 60px var(--pad);

  :global(.CAT--desktop) & {
    padding: 0;
  }
}

.controlsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--blue);
  position: sticky;
  top: 0;
  background-color: var(--white);
  padding: 0.5rem var(--pad);
  width: 100%;

  font-weight: bold;
  border-bottom: 1px solid var(--light-blue);
}
.controlsInner {
  padding: var(--pad);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    border-left: 1px solid var(--light-blue);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--medium-blue);
    // border-radius: 10px;
  }
}

.toggle {
  font: inherit;
  background-color: var(--blue);
  color: var(--white);
  border-radius: var(--border-radius);
  border: 1px solid var(--blue);
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  z-index: 1000;
  height: var(--toggle-height);
  width: 100%;

  @extend %font-body;

  svg {
    display: block;

    * {
      stroke: var(--white);
    }
  }
}

.toggleShow {
  composes: toggle;
  font: inherit;

  position: fixed;
  z-index: 1000;
  top: 1rem;
  left: 1rem;
  width: calc(100% - 2rem);
  cursor: pointer;
}

.reset {
  @extend %toggleButtonLight;
  margin-bottom: 1em;
}
