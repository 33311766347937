@import "~scss/imports";

.QuickFacts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -1rem;
}

.QuickFact {
  width: 178px;
  margin: 1rem;
}

.QuickFact-number {
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid var(--yellow);
  min-height: 178px;
  @extend %font-h2-sans;
}

.QuickFact-description {
  margin-top: 1em;
  @extend %font-quickfact;
}