$quote-hero-breakpoint: "medium";

.QuoteHero--inner {
  @include breakpoint($quote-hero-breakpoint) {
    display: grid;
    grid-gap: 2.5rem 0;
    grid-template-areas: "image ." "image text" "image caption" "image .";
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr auto auto 1fr;
    position: relative;
  }

  .QuoteHero--alt & {
    @include breakpoint($quote-hero-breakpoint) {
      grid-template-areas: ". image" "text image" "caption image" ". image";
      grid-template-columns: 2fr 3fr;
    }
  }
}

.QuoteHero--inner {
  // @extend %bigmax;
}

.QuoteHero_image {
  grid-area: image;
  display: flex;

  img {
    object-fit: cover;
  }
}

.QuoteHero_quote--text {
  @extend %font-body;
  font-weight: 600;
  grid-area: text;
  line-height: 1.3;
  padding-left: 2rem;
  padding-right: 4rem;
  padding-top: 2rem;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  @include breakpoint($quote-hero-breakpoint) {
    padding-top: 0;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @include breakpoint("desktop") {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.QuoteHero_quote--caption {
  @extend %font-bodysmall;
  grid-area: caption;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  text-transform: uppercase;

  p {
    margin-bottom: 0;
  }

  @include breakpoint($quote-hero-breakpoint) {
    padding-bottom: 0;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @include breakpoint("desktop") {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
