@import '~scss/imports';

.element {
  margin-top: var(--pad);
}

.heading {
  @extend %font-h2-sans;
  font-weight: bold;
  max-width: var(--max);
  margin: 0 auto;
  padding: 0 var(--article-pad);
  text-align: center;
}
