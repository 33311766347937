@import '~scss/imports';

.toggleButton {
  @extend %toggleButton;
  border: 0;
}

.toggleButtonActive {
  composes: toggleButton;
  background-color: var(--blue);
  color: var(--white);
  cursor: auto;
}
