@import "~scss/imports";

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  > div:first-child {
    font-weight: bold;
  }
}

.selection {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-weight: bold;
}

.button {
  @extend %toggleButton;
  text-align: left;
}

.resetButton {
  @extend %resetButton;
  margin-left: 1rem;
}

.headerEdit {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  // border: 1px solid var(--blue);
  width: 100%;
  margin: 0;
}

.variableHeader {
  margin: 0.5em 0;
}
