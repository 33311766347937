@import './color.scss';
@import './type.scss';
@import './layout.scss';

%toggleButton {
  background-color: var(--light-blue);
  border-radius: var(--button-radius);
  border: 1px solid var(--light-blue);
  padding: 0.15em 0.375em;
  cursor: pointer;
  @extend %font-body;
  font-size: 1rem;
  transition-property: background-color, color, border-color;
  transition-duration: 300ms;

  &:hover {
    border-color: var(--blue);
  }
}

%toggleButtonLight {
  background-color: transparent;
  border-radius: var(--button-radius);
  border: 1px solid var(--blue);
  padding: 0.15em 0.375em;
  cursor: pointer;
  @extend %font-body;
  font-size: 1rem;
  transition-property: background-color, color, border-color;
  transition-duration: 300ms;
  color: var(--black);

  &:hover {
    border-color: var(--blue);
    background-color: var(--light-blue);
    color: var(--black);
  }
}

%resetButton {
  border: 0;
  font-size: 100%;
  color: var(--blue);
  margin-left: auto;
  cursor: pointer;
  background-color: transparent;
}
