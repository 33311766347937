.Loading--module {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-blue);
  min-height: 80vh;
}

.LoadingDots {
  display: flex;
  justify-content: center;
  padding: 1rem;

  > div {
    animation: fadeIn 600ms infinite alternate;
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    margin: .25rem;

    &:nth-child(2) {
      animation-delay: 200ms;
    }
     &:nth-child(3) {
      animation-delay: 400ms;
    }
  }
}