@import "~scss/imports";

.CommunityResources {
  padding: 1rem;
  text-align: center;

  a {
    color: inherit;
    @extend %linkhover;
  }
}

.CommunityResources-inner {
  background-color: var(--yellow);
  @extend %pill;
  @extend %max;
}

.CommunityResources-heading {
  margin-top: 0;
  @extend %font-h2-sans-bold;
}

.CommunityResources-text {
  @extend %font-body;
}

.CommunityResources-disclaimer {
  @extend %font-citation;
  max-width: 40em;
  @extend %marginauto;
}

.CommunityResources-contact {
  list-style-type: none;
  align-items: center;
  display: flex;
  padding: 0;
  justify-content: center;

  a {
    align-items: center;
    display: flex;
    margin: .5rem;
    svg {
      display: block;
      margin-right: .25rem;

      * {
        fill: var(--black);
      }
    }
  }
}