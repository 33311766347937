@font-face {
  font-family: 'Financier';
  font-weight: 400;
  font-style: normal;
  src: url('./fonts/FinancierTextWeb-Regular.woff2') format('woff2'),
    url('./fonts/FinancierTextWeb-Regular.woff') format('woff'),
    url('./fonts/FinancierTextWeb-Regular.eot') format('eot');
}
@font-face {
  font-family: 'Financier';
  font-weight: 400;
  font-style: italic;
  src: url('./fonts/FinancierTextWeb-RegularItalic.woff2') format('woff2'),
    url('./fonts/FinancierTextWeb-RegularItalic.woff') format('woff'),
    url('./fonts/FinancierTextWeb-RegularItalic.eot') format('eot');
}

@font-face {
  font-family: 'Financier';
  font-weight: 600;
  font-style: normal;
  src: url('./fonts/FinancierTextWeb-Medium.woff2') format('woff2'),
    url('./fonts/FinancierTextWeb-Medium.woff') format('woff'),
    url('./fonts/FinancierTextWeb-Medium.eot') format('eot');
}
@font-face {
  font-family: 'Financier';
  font-weight: 600;
  font-style: italic;
  src: url('./fonts/FinancierTextWeb-MediumItalic.woff2') format('woff2'),
    url('./fonts/FinancierTextWeb-MediumItalic.woff') format('woff'),
    url('./fonts/FinancierTextWeb-MediumItalic.eot') format('eot');
}

@font-face {
  font-family: 'Dia';
  font-weight: 400;
  font-style: normal;
  src: url('./fonts/Dia Regular.woff') format('woff'),
    url('./fonts/Dia Regular.eot') format('eot');
}
@font-face {
  font-family: 'Dia';
  font-weight: 400;
  font-style: italic;
  src: url('./fonts/Dia Regular Italic.woff') format('woff'),
    url('./fonts/Dia Regular Italic.eot') format('eot');
}
@font-face {
  font-family: 'Dia';
  font-weight: 700;
  font-style: normal;
  src: url('./fonts/Dia Bold.woff') format('woff'),
    url('./fonts/Dia Bold.eot') format('eot');
}
@font-face {
  font-family: 'Dia';
  font-weight: 700;
  font-style: italic;
  src: url('./fonts/Dia Bold Italic.woff') format('woff'),
    url('./fonts/Dia Bold Italic.eot') format('eot');
}
