@import '~scss/imports';

.Stream {
  //position: relative;
}
.Block {
  position: relative;
}

.Stream-loadmore {
  @extend %cta-link;
  @extend %marginauto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.StreamResults {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
}

.StreamFilter {
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;

  margin-top: 2rem;
  margin-bottom: 2rem;

  @include breakpoint('tablet') {
    margin-top: calc(4vw + 2rem);
    margin-bottom: calc(4vw + 2rem);
  }
  // position: sticky;
  // top: var(--nav-height);
}
.StreamFilter-toggle {
  display: block;
}
.StreamFilter-inner {
  z-index: 1;
  background-color: var(--yellow);
  align-items: flex-start;
  padding: 1rem;
  border-radius: 10px;
  display: grid;
  grid-gap: 1em;

  label > div {
    margin-top: 0.25rem;
  }

  @media (min-width: 600px) {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    .StreamFilter-query {
      grid-column: 1/-1;
    }
  }

  @media (min-width: 1200px) {
    width: 66%;
    margin: 0 auto;
  }
}
.StreamFilterDropdown {
  width: 100%;

  > div {
    @extend %font-body;
  }

  svg {
    height: 100%;
  }
}
.StreamFilter-unstick {
  position: sticky;
  top: 0;
  margin-top: auto;
}
.StreamFilter-categories {
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
}
.StreamFilter-categories-checkbox {
  display: block;
}
.StreamFilter-query {
  > div {
    align-items: center;
    border: 1px solid var(--black);
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  input {
    background-color: unset;
    border: 0;
    font-size: 100%;
    padding: 10px 2rem 10px 5px;
    width: 100%;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 2px black;
    }
  }
  svg {
    position: absolute;
    right: 0;
    margin-right: 0.25rem;
    fill: var(--black);
    * {
      fill: inherit;
    }
  }
}

.CheckSet button {
  background-color: unset;
  border-radius: 0;
  border: 0;
  font-size: 100%;
  padding: 0;
  text-align: left;
}

.StreamCards {
  --gap: 1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--gap);

  // display: grid;
  // grid-gap: 1rem;
  // justify-content: center;

  @media (min-width: 600px) {
    .StreamCard {
      width: calc(50% - var(--gap) / 2);
    }
    //grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 900px) {
    .StreamCard {
      width: calc(33.33% - var(--gap) * 2 / 3);
    }
    // grid-template-columns: repeat(3, 1fr);
  }
}
.StreamCards-noresults {
  grid-column: 1/-1;
  text-align: center;
  @extend %font-h2-sans;
}

.StreamCard {
  text-decoration: none;
  color: inherit;
  background-color: var(--white);
  display: grid;
  // border: 2px solid transparent;

  &:hover {
    box-shadow: 2px 2px 2px 0 #d6e3f5;
    color: var(--blue);
  }
  // &:visited {
  //   color: #;
  // }
}

.StreamCard-inner {
  grid-gap: 1rem;
  padding: 16px 20px 20px;
  display: grid;
  grid-template-areas:
    'type'
    'image'
    'title'
    'description'
    'authors'
    'date';
  grid-template-rows: auto auto auto 1fr auto auto;
}

.StreamCard--featured .StreamCard-inner {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'image type'
    'image title'
    'image description'
    'image authors'
    'image date';

  > * {
    margin-top: 0;
  }
}

.StreamCard-type {
  grid-area: type;
  color: var(--blue);
  @extend %font-h5-sans;
}

.StreamCard-title {
  grid-area: title;
  @extend %font-h3-serif;
  //margin-top: 16px;
}
.StreamCard-image {
  grid-area: image;
  //margin-top: 12px;
}
.StreamCard-description {
  grid-area: description;
  @extend %font-body;
  margin-top: 12px;
}

.StreamCard-authors {
  grid-area: authors;
  @extend %font-source;
  //padding-top: 30px;
  margin-top: auto;
  min-height: 2em;
  box-sizing: content-box;
  display: flex;
  align-items: flex-end;
}
.StreamCard-date {
  grid-area: date;
  @extend %font-h5-sans;
  //margin-top: 12px;
}

.StreamCard--placeholder {
  background-color: var(--light-blue);
  height: 300px;
  animation: pulse 300ms infinite alternate;

  .App--lightBlue & {
    background-color: var(--white);
  }
}
@keyframes pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

.StreamCard--Publications {
  display: flex;
  background-color: var(--light-yellow);
  position: relative;

  &.StreamCard--featured .StreamCard-inner {
    grid-template-areas:
      'title type'
      'title description'
      'title authors'
      'title date';
    grid-template-columns: 40% 1fr;
  }

  &:before {
    content: '';
    background-image: linear-gradient(
      to bottom,
      var(--yellow-gradient-end),
      var(--yellow-gradient-start)
    );
    display: block;
    width: 16px;
    flex-shrink: 0;
  }

  .StreamCard-title {
    @extend %font-h2-serif;
  }
}

.StreamCard--News {
  // border: 2px solid var(--blue);
  background-color: #ceeaea;
  // color: var(--blue);
  .StreamCard-inner {
    grid-template-areas:
      'type'
      'title'
      'description'
      'authors'
      'date';
    grid-template-rows: auto auto 1fr auto auto;
  }

  .StreamCard-title,
  .StreamCard-description {
    // color: var(--blue);
  }
}

.RadioSet {
  border: 0;
  padding: 0;
  margin: 0;
}

.RadioSet-heading {
  margin: 0;
  position: static;
}

.RadioOption {
  display: flex;
  align-items: center;
  input[type='radio'] {
    height: 0;
    width: 0;
    margin: 0;
    visibility: hidden;
    display: block;

    &:checked + .Radio-label:before {
      background-color: var(--black);
    }
  }
  .Radio-label {
    display: inline-flex;
    align-items: center;
  }
  .Radio-label:before {
    content: '';
    width: 1em;
    height: 1em;
    border: 1px solid;
    display: inline-block;
    border-radius: 50%;
    margin-right: 0.5em;
  }
}

.RadioSet-options {
  display: flex;
  align-items: center;
  height: 44px;
  border: 1px solid;
  padding: 2px 8px;
  margin-top: 0.25rem;
  @extend %font-body;

  .RadioOption {
    margin-right: 1em;
  }
}
