@import '~scss/imports';

.toggle {
  // padding: 1em;
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  gap: 0.5rem 1rem;
  // border: 1px solid var(--yellow);
  // border-radius: var(--border-radius);

  @extend %font-nav;
  // font-size: 120%;
  padding: 0;
  // margin: 1em 0;

  // > span {
  //   flex-shrink: 0;
  // }

  // @media (min-width: 700px) {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  // }

  // @media (min-width: 1200px) {
  //   grid-template-columns: repeat(3, 1fr);
  // }
  // @media (min-width: 768px) {
  //   flex-direction: row;
  // }
}

.small {
  // padding: 1em;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 1rem;
  // border: 1px solid var(--yellow);
  // border-radius: var(--border-radius);

  @extend %font-nav;
  // font-size: 120%;
  padding: 0;
  // margin: 1em 0 0;

  > span {
    flex-shrink: 0;
  }

  > span:first-child {
    display: none;
  }
}

.toggleButton {
  text-align: left;
  display: flex;
  gap: 1em;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: 1px solid var(--blue);
  border-radius: 5px;
  padding: 0.33em 0.5em;
  @extend %font-bodysmall;
  font-size: 1.1rem;
  line-height: 1;
  cursor: pointer;
  transition: background-color var(--transition-timing);

  > svg {
    width: 12px;
  }

  &:hover,
  &:focus-visible {
    background-color: var(--light-blue);
  }

  > span {
    transition: transform var(--transition-timing);
  }
}

.activeToggleButton {
  composes: toggleButton;
  // pointer-events: none;
  background-color: var(--blue) !important;
  border-color: var(--blue) !important;
  color: var(--white) !important;

  svg {
    opacity: 0;
  }
  > span {
    transform: translateX(0.9em);
  }
}
