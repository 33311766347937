$fonts: (
  ('h1-sans', 'h1', 'sans', 700),
  ('h1-serif', 'h1', 'serif', 600),
  ('h2-sans-bold', 'h2', 'sans', 700),
  ('h2-sans', 'h2', 'sans', 400),
  ('h2-serif', 'h2', 'serif', 600),
  ('h2-serif-regular', 'h2', 'serif', 400),
  ('h2-sans-regular', 'h2', 'sans', 400),
  ('h3-sans', 'h3', 'sans', 400),
  ('h3-sans-bold', 'h3', 'sans', 700),
  ('h3-serif', 'h3', 'serif', 400),
  ('h4-sans', 'h4', 'sans', 400),
  ('h4-sans-bold', 'h4', 'sans', 700),
  ('h5-sans', 'h5', 'sans', 400),
  ('h5-serif', 'h5', 'serif', 400),
  ('body', 'body', 'sans', 400),
  ('bodysmall', 'bodysmall', 'sans', 400),
  ('body-bold', 'body', 'sans', 700),
  ('bodyfeatured', 'bodyfeatured', 'sans', 400),
  ('callout', 'callout', 'sans', 400),
  ('callout-bold', 'callout', 'sans', 700),
  ('cta', 'cta', 'sans', 700),
  ('iconlabel', 'h3', 'sans', 400),
  ('source', 'cta', 'sans', 700),
  ('fact', 'fact', 'serif', 600),
  ('nav', 'cta', 'sans', 700),
  ('quickfact', 'quickfact', 'sans', 400),
  ('citation', 'citation', 'sans', 400),
  ('article-h1', 'article-h1', 'serif', 600),
  ('article-h2', 'article-h2', 'serif', 600),
  ('article-h3', 'article-h3', 'sans', 400),
  ('article-h4', 'article-h4', 'sans', 700),
  ('article-h5', 'article-h5', 'sans', 400)
);

@each $font in $fonts {
  %font-#{nth($font,1)} {
    font-family: var(--#{nth($font, 3)});
    font-size: var(--size-#{nth($font, 2)});
    font-weight: #{nth($font, 4)};
    line-height: var(--line-#{nth($font, 2)});
  }
}

%data-note {
  @extend %font-body;
  font-size: 90%;
  line-height: 1.1;
}

%cta-link {
  color: inherit;
  display: inline-block;
  @extend %font-cta;
  text-transform: uppercase;
  text-decoration: none;
  // padding-bottom: 8px;
  border-bottom: 2px solid var(--blue);

  background-color: unset;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  cursor: pointer;

  &:hover {
    border-bottom-color: var(--yellow);
  }

  .bg--blue & {
    border-bottom-color: var(--yellow);
    &:hover {
      border-bottom-color: var(--white);
    }
  }
  .bg--white & {
    border-bottom-color: var(--blue);
    &:hover {
      border-bottom-color: var(--yellow);
    }
  }
}

%linkhover:hover {
  color: var(--blue);
  text-decoration: none;

  .bg--blue & {
    color: var(--yellow);
  }
  .bg--white & {
    color: var(--blue);
  }
}
