@import '~scss/imports';

%base-article {
  --toc-border-radius: 20px;
  @extend %max;
  margin-top: var(--article-pad);
  padding: 0 1em;

  [id]:before {
    content: '';
    display: block;
    height: var(--nav-height);
    margin-top: calc(-1 * var(--nav-height));
    pointer-events: none;
  }
}

.PublicationArticle {
  @extend %base-article;

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 2em;
    --width: 320px;

    .PublicationArticle-toc {
      width: var(--width);
      top: calc(var(--nav-height) + 1rem);
      overflow: hidden;
      margin-left: 1em;
    }
    .PublicationFigure {
      max-width: calc(100vw - var(--article-pad) * 2 - var(--width) - 30px);
    }
  }
}

.PublicationArticle--no-toc {
  @extend %base-article;
}

.PublicationArticle-toc {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;

  &.open {
    @media (min-width: 1000px) {
      height: calc(100vh - var(--nav-height) - 2rem);
      position: sticky;
      top: var(--nav-height);
      z-index: 1;
    }
  }

  @extend %maxarticle;
  @extend %marginauto;

  .thumb-vertical {
    background-color: var(--yellow);
    opacity: 0.5;
  }

  .PublicationArticle-toc-inner {
    height: 100%;
    border-bottom-left-radius: var(--toc-border-radius);
    border-bottom-right-radius: var(--toc-border-radius);
    overflow: hidden;

    a {
      text-decoration: none;
      &:hover,
      &:focus {
        color: var(--blue);
      }
    }

    ul {
      list-style-type: none;
    }
  }
}

.PublicationArticle-toc-level--1,
.PublicationArticle-toc-title,
.PublicationArticle-toc-toggle {
  background-color: var(--light-yellow);

  padding-left: 1rem;
  padding-right: 1rem;

  @media (min-width: 1000px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.PublicationArticle-toc-level--1 {
  padding-top: 1rem;
  padding-bottom: 0.75rem;

  margin-top: 0;
  margin-bottom: 0;

  > li {
    font-weight: bold;
  }
  > li + li {
    margin-top: 1rem;
  }
}
.PublicationArticle-toc-level--2 {
  padding-left: 0.5rem;
  margin-top: 1rem;
  font-weight: normal;

  li {
    margin-top: 0.5rem;
  }
}

.PublicationArticle-toc-title,
.PublicationArticle-toc-toggle {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--yellow);
  border-top-left-radius: var(--toc-border-radius);
  border-top-right-radius: var(--toc-border-radius);

  display: flex;
  justify-content: space-between;
}
.PublicationArticle-toc-title {
  font-family: var(--serif);
  font-size: 20px;
  font-weight: 600;
}

.PublicationArticle-toc-toggle {
  cursor: pointer;
  border: 1px solid var(--yellow);
  font-size: 1.5em;
  font-family: var(--serif);
  font-weight: 600;
  display: block;
  transition: background-color 0.3s;

  &.closed {
    border-bottom-left-radius: var(--toc-border-radius);
    border-bottom-right-radius: var(--toc-border-radius);
  }

  &.open {
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }

  &:hover {
    background-color: var(--yellow);
  }

  &:focus {
    outline: 0;
    color: var(--blue);
  }
}

.PublicationArticle-toc-toggle,
.PublicationArticle-toc-inner {
  width: calc(100% - var(--article-pad) * 2);
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1000px) {
    width: 100%;
  }
}

.PublicationArticle {
  .ReleaseAssessmentBlock,
  .ReleaseAssessment {
    background-color: transparent;
  }
  .ReleaseAssessmentBlock {
    padding: var(--article-box-pad);

    .Block-inner {
      border-radius: 50px;
      padding: 2rem;
    }
  }
  .ReleaseAssessment-header {
    color: var(--blue);
  }
}
