@import "~scss/imports";

.Person {
  display: grid;
  grid-template-areas:
    "image name"
    "image title"
    "image email"
    "image ."
    "bio bio"
  ;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto auto 1fr;
  grid-gap: 1rem;
  padding: 2rem 1rem;

  .Person-image {
    max-width: 115px;
    @include breakpoint('mobile') {
      max-width: unset;
      width: 200px;
    }
    @include breakpoint('tablet') {
      width: 300px;
    }
    @include breakpoint('desktop') {
      width: 400px;
    }
  }

  @include breakpoint('tablet') {
    grid-template-areas:
      "image name"
      "image title"
      "image email"
      "image bio"
    ;
    grid-template-areas:
      "image name"
      "image title"
      "image email"
      " bio"
    ;
    grid-column-gap: 4vw;
    grid-template-columns: minmax(1fr,400px) 1fr;

    grid-template-rows: auto auto 8em 1fr;
    grid-template-rows: auto auto auto 1fr;

    padding-top: 100px;
    padding-bottom: 100px;

    .Person-image {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// .Person-name,
// .Person-title,
// .Person-email,
// .Person-bio {
//   padding-left: 1rem;
//   padding-right: 1rem;
// }

.Person-image {
  width: 100%;
  grid-area: image;
  align-self: start;
}

.Person-name {
  @extend %font-h1-serif;
  grid-area: name;
}

.Person-title {
  @extend %font-h3-sans;
  grid-area: title;
}

.Person-email {
  @extend %font-bodyfeatured;
  grid-area: email;
  color: inherit;
  text-decoration: unset;
  align-self: center;

  >svg {
    margin-right: 1rem;
  }
}

.Person-bio {
  @extend %font-body;
  grid-area: bio;

  p:first-child {
    margin-top: unset;
  }
  p:last-child {
    margin-bottom: unset;
  }
}

.PersonWork {
  @extend %bg--light-blue;
}