@import "~scss/imports";

.Jobs {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-gap: 2em;

  @include breakpoint('tablet') {
    grid-template-columns: repeat(2,1fr);
  }

  @include breakpoint('desktop') {
    grid-template-columns: repeat(3,1fr);
  }
}

.Jobs-item {

  border: 2px solid var(--salmon);
}

.Jobs-item a {
  background-color: var(--salmon);
  display: flex;
  flex-direction: column;
  color: inherit;
  height: 100%;
  text-decoration: unset;
  text-align: center;
  padding: 1em 1.5em;

  &:hover {
    background-color: var(--white);
  }
}

.Jobs-item-heading {
  @extend %font-h3-serif;
  line-height: 1;
  min-height: 2em;
}

.Jobs-item-cta {
  @extend %font-cta;
  text-transform: uppercase;
  display: inline-block;
  padding-bottom: .6rem;
  padding-top: 1em;
  border-bottom:  2px solid var(--salmon);
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
}