html {
  box-sizing: border-box;
}
* {
  box-sizing: inherit;
}

img {
  display: block;
  width: 100%;
  height: auto;
}
figure {
  margin: 0;
}

ul.reset {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: unset;
}

button {
  line-height: unset;
  font-family: unset;
  padding: unset;
}