@import "~scss/imports";

.ReleaseAssessmentBlock {
}

.ReleaseAssessmentBlock-button {
  border-top: unset;
  border-left: unset;
  border-right: unset;
  background-color: unset;
  @extend %cta-link;
  margin-top: 2em;
}

.ReleaseAssessment-main-button {
  margin-left: 1em;
  font-size: unset;
  padding: 0;
  background-color: unset;
  border: 0;
  color: var(--green);
}

.ReleaseAssessment-main-button[disabled] {
  opacity: 0.25;
}
