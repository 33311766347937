@import "~scss/imports";

.Video {

  video {
    display: block;
    width: 100%;
    @extend %max;

    &:focus {
      outline: 2px solid var(--blue);
    }
  }
}