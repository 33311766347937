/* autoprefixer grid: autoplace */

@import '~scss/imports';
@import 'Carousel';
@import 'QuoteHero';
@import 'StandardHero';
@import 'StaffPerson';

h2.Heading {
  @extend %font-h2-sans-bold;
  text-align: center;
  margin-bottom: 2rem;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.twocolumn {
  @extend %twocolumn;
  @extend %max;

  .Text {
    margin-left: unset;
    @extend %font-bodyfeatured;
    @extend %maxarticle;
  }
}
.Block-inner {
  @extend %max;

  .Block.layoutWidth--fullWidth & {
    @extend %bigmax;
  }

  &.has-bg {
    padding: 1rem;

    @include breakpoint('tablet') {
      padding: 4rem;
    }
  }
}
.Image.loading {
  background-color: var(--light-blue);
}

.Block,
.Container {
  // padding-bottom: 4rem;
  // padding-left: 1em;
  // padding-right: 1em;
  // padding-top: calc(2rem + 4vw);

  padding: var(--margin-vertical) var(--pad);

  &.layoutWidth--fullWidth {
    padding-left: 0;
    padding-right: 0;
  }

  &.layoutWidth--fullWidth {
    padding-bottom: 0;
  }

  // @include breakpoint('tablet') {
  //   padding-top: calc(2rem + 4vw);
  //   padding-bottom: calc(2rem + 4vw);
  // }
}
.Block > h2 {
  @extend %font-h2-sans;
  text-align: center;
}

.innerLayout--whitePill .Block-inner {
  @extend %bg--white;
  @extend %color--black;
  @extend %pill;
  @extend %marginauto;
  @extend %max;
}

.Block.innerLayout--whitePill .Block-inner {
  padding: 2rem;
  @include breakpoint('tablet') {
    padding: 4rem 10vw;
  }
}

.BlockAnchor {
  position: relative;
  // top: calc(-1 * var(--nav-height));
}

.Text {
  @extend %maxarticle;
  @extend %marginauto;

  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }

  > * {
    @extend %font-body;
  }
}

.Explainer {
  @extend %font-bodyfeatured;
  @extend %marginauto;
}

.IconAnchorsHero {
  align-items: center;
  background-color: var(--blue);
  color: var(--white);
  display: flex;
  justify-content: center;
}
.IconAnchorsHero {
  text-align: center;
  @extend %BlockedHero;

  h1 {
    @extend %font-h1-sans;
  }
  h1,
  p {
    margin: 0;
  }
  h1 + .Explainer {
    margin-top: 2rem;
  }
  .Explainer {
    @extend %font-body;
    @extend %maxtext;
  }
  .IconSet {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: 2rem;

    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint('tablet') {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
    }
  }
  .IconSet-item {
    @extend %font-iconlabel;
    figcaption {
      margin-top: 1rem;
    }
  }
}
.IconAnchorsHero-inner {
  width: 100%;
  @extend %max;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.IconSet-item img {
  max-width: 80px;
  @include breakpoint('desktop') {
    max-width: 120px;
  }
  @extend %marginauto;
}

.OurServicesHero {
  @extend %BlockedHero;
  text-align: center;

  h1 {
    @extend %font-h1-sans;
  }
  h2 {
    @extend %font-h2-sans;
  }
  .Explainer {
    @extend %font-bodyfeatured;
  }

  .Things {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin-bottom: -2em;
    .Things-item {
      margin-bottom: 2rem;
    }
    @include breakpoint('tablet') {
      margin-bottom: -4rem;
      .Things-item {
        margin-bottom: 4rem;
      }
    }

    .Things-item {
      @include breakpoint('desktop') {
        width: calc(50% - 2em);
        &:nth-child(odd):not(:last-child) {
          padding-right: 2em;
        }
        &:nth-child(even) {
          padding-left: 2em;
        }
      }
    }

    &:not(:first-child) {
      margin-top: 62px;
    }
  }
  .Things-item-heading {
    @extend %font-h3-sans;
    margin-top: 0;
  }
  .Things-item-text {
    @extend %font-body;
  }
}

.OurServicesHero-inner {
  @extend %BlockedHero-inner;
  padding-top: 4rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;

  h2:not(:first-child) {
    margin-top: 121px;
  }

  @include breakpoint('tablet') {
    padding-top: 86px;
    padding-bottom: 86px;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .Explainer {
    @extend %maxtext;
  }
}

.OurServicesHero-icon {
  width: 86px;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(50%);
}

// .People-item {
//   padding-left: 40px;
//   padding-right: 40px;
// }
.People-item {
  // max-width: 200px;
  margin: 1rem;
}
.People-item a {
  color: inherit;
  text-decoration: none;
}

.People-item-name {
  @extend %font-h4-sans;
  margin-top: 23px;
}
.People-item-title {
  margin-top: 8px;
}
.People-item-name,
.People-item-title {
  text-align: center;
}

.CtaExplainer {
  max-width: 858px;
  @extend %marginauto;
  text-align: center;
}
.CtaExplainer-heading {
  @extend %font-h1-sans;
  &:first-child {
    margin-top: unset;
  }
}
.CtaExplainer-body {
  @extend %font-body;
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
.CtaExplainer-link {
  @extend %cta-link;
  display: inline-block;
  margin-top: 2rem;
}
.CtaExplainer-disclaimer {
  @extend %font-h5-sans;
  max-width: 500px;
  @extend %marginauto;
  margin-top: 3rem;
  text-align: center;
}

.Callout {
  @extend %font-h2-serif;
  @extend %maxarticle;
  @extend %marginauto;
  color: var(--blue);
}

.Fact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // color: var(--white);
  // padding: 8rem;
  padding: 1em;
  text-align: center;
  position: relative;

  border-radius: var(--border-radius);

  background-color: var(--blue);
  color: var(--white);

  // > svg {
  //   fill: var(--blue);
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  // }
  > * {
    position: relative;
  }
}
.Fact-heading {
  @extend %font-fact;
}
.Fact-text {
  @extend %font-h2-sans;
}

.Publication {
  background-color: var(--white);
  margin-top: 50px;

  main {
    padding-bottom: 4rem;
    margin-bottom: 6rem;
  }
}

.iframe-wrapper {
  padding-bottom: 56.25%;
  height: 0;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
