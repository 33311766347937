@import '~scss/imports';
$icontogglesize: 30px;

.CAT {
  @extend %max;
  padding: 0 var(--article-pad);
  display: grid;
  grid-gap: 2rem;
  @extend %blockmarginvertical;
  position: relative;
}
.CAT-nosettings {
  max-width: var(--maxarticle);
  margin: 0 auto;
}

.CAT-controls-staticcards-group {
  display: flex;
  flex-direction: column;
  gap: 1em;

  & + .CAT-controls-staticcards-group {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--yellow);
  }
}
.CAT-controls-staticcards-group-heading {
  @extend %font-h4-sans;
  margin-bottom: 0.5em;
}
.CAT-controls-staticcards-group-subheading {
  @extend %font-bodysmall;
  margin-top: 1em;
}
// .CAT-controls-staticcards-item-inner > * {
//   padding-bottom: 1rem;
// }
.CAT-tool-hassettings {
  display: grid;
  font-family: 'Dia';
  border-top: 1px solid;
  // transition: grid-template-columns 1s;

  .CAT--desktop & {
    grid-template-columns: var(--dashboard-template-columns);
    grid-gap: 1rem;
  }
}

.CAT-tool-controlshidden {
  .CAT--desktop & {
    grid-template-columns: auto 1fr;
  }
}
.CAT-explainer {
  text-align: center;
}

.CAT-explainer h1 {
  @extend %font-h1-sans;
}

.CAT-explainer-body {
  @extend %font-bodyfeatured;
  @extend %maxarticle;
  @extend %marginauto;
}

.CAT-explainer-citation {
  @extend %font-bodysmall;

  dd {
    margin-left: 0;
    margin-top: 0.5em;
  }
}

.CAT-controls {
  display: grid;
  grid-gap: 1rem;
  align-items: center;

  .CAT--desktop & {
    align-self: start;
    // padding-right: 1em;
  }

  .CtaExplainer-link {
    justify-self: center;
  }
}

.CAT-nodata {
  @extend %font-h3-sans;
  padding: 1em;
}

.CAT-controls-explainer {
  background-color: var(--light-blue);
  padding: 1em;
  width: 100%;
}

.CAT-controls-add {
  align-self: center;
  display: inline-block;
  cursor: pointer;
  background-color: var(--blue);
  color: var(--white);
  border: 0;
  border-radius: var(--button-radius);
  font-size: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid;
  margin: 0 auto;

  &:hover,
  &:focus {
    border-color: var(--yellow);
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.CAT-controls-item {
  width: 100%;
}

.CAT-controls-reset {
  @extend %resetButton;

  &:hover,
  &:focus {
    border-color: var(--yellow);
  }
}
.CAT-controls-staticcards {
  width: 100%;
}
.CAT-controls-staticcards-item {
  display: block;

  & + .CAT-controls-staticcards-item {
    margin-top: 1rem;
  }

  input[type='radio'] {
    appearance: none;
    width: 0;
    height: 0;
    margin: 0;
    position: absolute;
  }
  .CAT-controls-variable-sliceby {
    padding: 0 1rem;
    margin: 1rem 0 0;
  }
}
.CAT-controls-staticcards-item--inactive {
  @extend .CAT-controls-staticcards-item;
  label {
    cursor: pointer;

    &:hover {
      .CAT-controls-staticcards-item-label {
        border-color: var(--blue);
      }
    }
  }
}
.CAT-controls-staticcards-item--active {
  --pad: 1rem;
  @extend .CAT-controls-staticcards-item;
  border: 1px solid var(--blue);
  border-radius: var(--border-radius);
  padding-bottom: var(--pad);
  // padding-top: calc(var(--pad) / 2);

  .CAT-controls-staticcards-item-label {
    // color: var(--blue);
    background-color: transparent;
    padding-bottom: 0.5rem;
    // font-size: 120%;
  }
  .CAT-controls-staticcards-item-description,
  .CAT-controls-variable-filters {
    padding-left: var(--pad);
    padding-right: var(--pad);
  }
  .CAT-controls-staticcards-item-label > svg {
    visibility: hidden;
  }
}
.CAT-controls-staticcards-item-label {
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--border-radius);
  background-color: var(--light-blue);
  width: 100%;
  font-weight: 700;

  svg {
    flex-shrink: 0;
    margin-left: 1em;
  }
  // svg * {
  //   stroke: currentColor;
  // }
}
.CAT-controls-staticcards-item-description {
  // margin-top: 1em;
  font-size: 90%;

  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
  ol,
  ul {
    padding-left: 1rem;
  }
}
.CAT-controls-variable {
  background-color: var(--white);
  border: 0;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  // margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }
  .Checkbox-label {
    background-color: var(--light-blue);
  }
}
.CAT-controls-variable--candrag {
  margin-top: 0;
  .DragHandle {
    &:hover {
      fill: var(--blue);
    }
  }
  &:hover {
    outline: 1px solid var(--blue);
  }
}

.CAT-controls-variable-inner {
  padding: 1rem;
  border: 1px solid var(--light-blue);
  border-radius: var(--border-radius);
  width: 100%;
}

.CAT-controls-variable-inner-explainer {
  @extend %data-note;
  p:last-child {
    margin-bottom: 0;
  }
}

.CAT-controls-variable-filters {
  padding-top: 0.5rem;
  display: flex;
  // flex-wrap: wrap;
  gap: 0.5rem;

  input[type='checkbox']:checked + .Checkbox-label {
    opacity: 1;
  }
  input[type='checkbox']:focus,
  input[type='checkbox']:hover {
    cursor: pointer;
    & + .Checkbox-label:before {
      border-color: var(--blue);
    }
  }
}

.CAT-controls-variable-filters-reset {
  background-color: transparent;
  color: var(--blue);
  display: block;
  font-size: 0.75rem;
  border-radius: 0;
  border: 0;
  margin-bottom: 0.25rem;

  &[disabled] {
    opacity: 0.5;
  }
}
.CAT-results {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  .StackedBars {
    height: 100%;
  }
  .CAT--desktop & {
    margin-left: var(--article-pad);
    margin-right: var(--article-pad);
  }
}
.CAT-results-note {
  font-size: 0.8em;
  // margin: 1rem 0 0;
  max-width: 50em;
  ul {
    list-style-type: '–';
    padding: 0;
    margin: 0;
    padding-left: 0.5em;
  }
}
.CAT-results-note-label {
  font-weight: bold;
}
.CAT-results-controls {
  background-color: var(--white);
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: var(--nav-height);
  z-index: 1;
  padding-bottom: 1rem;
  width: 100%;

  .IconToggle-icon {
    position: relative;
  }

  .IconToggle:nth-child(even) {
    padding-left: 0.25rem;
  }
  .IconToggle:nth-child(odd) {
    padding-right: 0.25rem;
  }
}
.CAT-results-controls-item {
  border: 0;
  padding: 0;
  margin: 0;

  &:not(:last-child) {
    margin-right: 2rem;
  }
}

.CAT-results-citation {
  margin-top: 1em;
  @extend %font-citation;
}

.ToggleSet {
  display: flex;
  flex-direction: column;

  .IconToggle-icon {
    border-color: transparent;
  }
  .IconToggle:nth-child(odd) .IconToggle-icon:after {
    right: 0;
  }
  .IconToggle:nth-child(even) .IconToggle-icon:after {
    left: 0;
  }
  .IconToggle.active .IconToggle-icon {
    background-color: var(--medium-blue);
    border-color: var(--blue);
    color: var(--blue);
    svg * {
      fill: var(--blue);
    }
  }
}
.ToggleSet-buttons {
  display: flex;
  justify-content: space-between;
  background-color: var(--light-blue);
  border-radius: $icontogglesize;

  .IconToggle {
    cursor: pointer;
  }
}
.ToggleSet-labels {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  text-align: center;
  font-size: 12px;
  margin-top: 3px;
}

.CAT-controls-variable-inner-togglefilters {
  font-size: 12px;
  @extend %cta-link;
  border-bottom: 0;
  padding: 0;
  margin-top: 1rem;

  &:hover,
  &:focus {
    outline: 0;
    text-decoration: underline;
  }
}

.Table {
  text-align: center;
  font-size: 14px;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  & + .Table {
    margin-top: 2em;
  }

  th:first-child {
    border-right: 1px solid;
  }

  th,
  td:first-child {
    background-color: var(--light-blue);
    width: 15em;
  }

  th,
  td:first-child {
    font-weight: normal;
  }

  td,
  th {
    padding: 10px;
    border-top: 1px solid;
    border-bottom: 1px solid;
    max-width: 10em;
  }
}

.Key-label {
  font-family: var(--sans);
  font-size: var(--size-h3);
  font-weight: 700;
  line-height: var(--line-h3);
  line-height: 1;
  margin-bottom: 1rem;
}
// .Key-label-filtergroup {
// }
.Key-label-filtered {
  display: flex;
  margin-bottom: 0.5em;
  width: 100%;
  align-items: flex-start;
  gap: 0.5em;
  font-size: 15px;
  font-weight: bold;
}
.Key-label-filtered__inner {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.Key-label-filtered-icon {
  width: 18px;
  display: block;
  padding-top: 0.25rem;

  * {
    fill: var(--blue);
  }
}

.Key-filteritem {
  background-color: var(--light-blue);
  padding: 0.25em 0.5em;
  border-radius: calc(var(--border-radius) * 0.25);
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  margin-left: 0.5rem;
}

th.Table-total,
td.Table-total {
  font-weight: bold;
}

.BlankCell {
  background-color: transparent !important;
  border: 0 !important;
}

.VariableCell {
  font-weight: bold !important;
}

.Table-label {
  background-color: var(--black);
  color: var(--white);
  font-weight: bold;
  padding: 0.5em;
}

.Bars-inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}
.BarLabel--primary {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 1em;
  font-size: 120%;
}
.BarGroup-inner {
  display: grid;
  grid-row-gap: 1rem;
}

.BarLabel {
  & + .Bar,
  & + .Null {
    margin-top: 0.2rem;
  }
}

.BarLabel-key {
  font-size: 12px;
}

.BarLabel-value {
  font-weight: bold;
}

.Bar {
  display: block;
}

.Null {
  align-items: center;
  display: flex;
  justify-content: center;
  svg {
    display: block;
    width: 1rem;
    height: 1rem;
  }
}
.Null-tooltip {
  margin-left: 0.25rem;
  border: 1px solid;
  border-radius: 50%;
  width: 1.125rem;
  height: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;

  svg {
    display: block;
    width: 1rem;
    height: 1rem;
  }

  &:hover,
  &:focus {
    background-color: var(--black);

    svg * {
      fill: var(--white);
    }
  }
}

.Key {
  display: flex;
  flex-direction: column;
  gap: 0.25em;

  > .BarLabel {
    .BarLabel-key {
      @extend %font-h3-sans;
      font-weight: bold;
    }
  }
}

.Key-items {
  display: flex;
  flex-wrap: wrap;
  // margin: auto -0.25rem 0;
  gap: 0.5em 1.5em;
}
.Key-item {
  align-items: center;
  // margin: 0.125rem 0.25rem;
  font-size: 12px;
  line-height: 1;
  // max-width: 11em;
  // width: 100%;
  display: flex;

  > svg {
    display: block;
    margin-right: 0.5rem;
    flex-shrink: 0;
    border-radius: 0.25em;
  }
}

.IconToggle {
  background-color: transparent;
  border: 0;
  color: inherit;
  text-decoration: none;
  text-align: center;
  display: block;

  &:focus,
  &:hover {
    outline: 0;
    border-color: var(--blue);

    .IconToggle-icon {
      background-color: var(--medium-blue);
    }

    &.active {
      border-color: var(--blue);
    }
  }

  &.active .IconToggle-icon {
    background-color: var(--black);
    color: var(--white);
    > svg * {
      fill: var(--white);
    }
  }
}

.IconToggle-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 50%;
  width: $icontogglesize;
  height: $icontogglesize;
  padding: 4px;
  background-color: var(--light-blue);

  > svg {
    display: block;
    width: 80%;

    * {
      fill: var(--black);
    }
  }
}

.IconToggle-label {
  font-size: 12px;
  margin-top: 3px;
  text-align: center;
}

.Checkbox {
  input[type='checkbox'] {
    appearance: none;
    display: block;
    width: 0;
    height: 0;
    margin: 0;
  }
  input[type='checkbox']:checked + .Checkbox-label {
    background-color: var(--blue);
    color: var(--white);
  }
  // input[type="checkbox"]:checked + .Checkbox-label:before {
  //   background-color: var(--blue);
  //   color: var(--white);
  //   content: "✓";
  // }
}
// .Checkbox--all {
//   align-self: stretch;
//   .Checkbox-label {
//     height: 100%;
//     align-items: center;
//   }
// }
.Checkbox-options {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.Checkbox-label {
  display: flex;
  @extend %toggleButton;
}
// .Checkbox-label:before {
//   content: "";
//   display: inline-block;
//   line-height: 1.2;
//   text-align: center;
//   width: 1rem;
//   height: 1rem;
//   flex-shrink: 0;
//   border: 1px solid;
//   margin-right: 0.25em;
// }

.Checkbox,
.Radio {
  display: block;
}

.Select {
  display: block;
  position: relative;
  $caretWidth: 2rem;

  select {
    appearance: none;
    background-color: transparent;
    border-radius: 0;
    border: 1px solid;
    color: var(--blue);
    cursor: pointer;
    font-size: 100%;
    padding: 0.25rem $caretWidth 0.25rem 0.25rem;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  &:after {
    content: '';
    align-items: center;
    background-image: url('~svg/select.svg');
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 1px;
    top: 1px;
    height: calc(100% - 2px);
    width: $caretWidth;
    background-color: var(--medium-blue);
    pointer-events: none;
    z-index: 0;
  }
}

.DropdownButton {
  display: block;
  cursor: pointer;
  border: 0;
  border-radius: 0;
  font-size: 0.75em;
  padding: 0.25em;

  &:hover {
    background-color: var(--blue);
    color: var(--white);
  }
}

@mixin type($background-color) {
  background-color: $background-color;
  border-radius: 0.75rem;
  &.place-top {
    &:after {
      border-top-color: $background-color;
      border-top-style: solid;
      border-top-width: 6px;
    }
  }
  &.place-bottom {
    &:after {
      border-bottom-color: $background-color;
      border-bottom-style: solid;
      border-bottom-width: 6px;
    }
  }
  &.place-left {
    &:after {
      border-left-color: $background-color;
      border-left-style: solid;
      border-left-width: 6px;
    }
  }
  &.place-right {
    &:after {
      border-right-color: $background-color;
      border-right-style: solid;
      border-right-width: 6px;
    }
  }
}
@mixin border($border-color) {
  border: 1px solid $border-color;
  &.place-top {
    &:before {
      border-top: 8px solid $border-color;
    }
  }
  &.place-bottom {
    &:before {
      border-bottom: 8px solid $border-color;
    }
  }
  &.place-left {
    &:before {
      border-left: 8px solid $border-color;
    }
  }
  &.place-right {
    &:before {
      border-right: 8px solid $border-color;
    }
  }
}

.CATTooltip {
  @include type(rgba(0, 0, 0, 1));
  // color: var(--black);
  max-width: 400px !important;
  opacity: 1;
  border-radius: 2px;

  &.view-tooltip {
    max-width: 250px !important;
  }

  p {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.CATTooltip,
.MobileCatTooltip {
  .color {
    width: 0.66rem;
    height: 0.66rem;
    position: relative;
    // top: -0.05rem;
    background-color: currentColor;
    border-radius: 2px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style: none;
    padding: 0;
    gap: 0.25em;
  }

  .column {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    gap: 0.5em;
  }
  .key {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .value {
    // font-size: 125%;
    margin-top: 0.1em;
    margin-left: 1.25rem;
  }

  .total {
    border-top: 1px solid;
    padding-top: 0.3rem;
    padding-bottom: 0.75rem;
  }
}

.MobileCatTooltip {
  margin-top: 1em;
  margin-left: 76px;
  padding: 1em;
  border-radius: var(--border-radius);
  border: 1px solid var(--blue);

  .total {
    padding-bottom: 0;
  }
  .tooltip-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.MobileCatTooltip-close {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;

  svg {
    display: block;
  }
}

.CATTooltip--mobile {
  display: none !important;
}

.CATTooltip-terms {
  font-size: 16px;
  line-height: 1.1;
  max-width: 400px !important;
}

.YearSpan {
  font-size: 12px;
}

.tooltip-date {
  font-weight: bold;
}

.total-key {
  margin-left: 1.25rem;
}
