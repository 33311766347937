@import '~scss/imports';

.controlsKey {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: space-between;
  position: relative;
  z-index: 1;
  // padding-bottom: 1rem;
  margin-bottom: 1rem;

  // &:after {
  //   content: '';
  //   width: 100%;
  //   border-bottom: 1px solid;
  //   position: absolute;
  //   bottom: 0;
  // }

  @media (min-width: 500px) {
    flex-direction: row;
  }
  // :global(.CAT--desktop) & {
  //   padding: 0;
  //   // padding-bottom: 1rem;
  // }
  :global(.Key) {
    width: 100%;
  }
}
.controlsKeyControls {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  // justify-content: flex-end;
  gap: 0.5rem;
  align-self: stretch;
  margin-left: auto;
}

.date {
  font-size: clamp(12px, 0.75vw, 0.75vw);
}

.selectedDate {
  @extend .date;
  text:first-child {
    font-weight: bold;
  }
}

.yearControls {
  // transform: translateY(-100%);
  height: 2rem;
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: flex-end;
  gap: 1em;
}

.yearButton {
  font-size: 1rem;
  @extend %toggleButton;
  border: 0;

  &[disabled] {
    visibility: hidden;
  }
}

.barsTitle {
  margin-top: 0;
  margin-bottom: 0.25em;
  font-size: var(--size-body);
  color: var(--blue);
}

.quarterYear {
  font-size: clamp(1em, 3vw, 1.5em);
}
