@import '~scss/imports';

.element {
  margin-top: var(--margin-vertical);
  margin-bottom: var(--margin-vertical);

  &:first-child {
    margin-top: 0;
  }
}

.header {
  background-color: var(--anniversary-purple);
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.heading,
.yearText {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}
.headingVisible {
  margin: 0;
  padding: 3em 0;
  text-align: center;
  @extend %font-h1-sans;
}
.carousel {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.controls {
  padding: 1.5em 1em;
  max-width: var(--max);
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.subheading {
  margin-left: 2em;
  margin-right: 2em;
  @extend %font-nav;
  text-transform: uppercase;
  color: var(--blue);
}

.button {
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 50%;

  svg {
    display: block;
    path {
      transition: stroke var(--transition-timing);
    }
  }
  &:focus-visible {
    outline: 2px solid var(--turquoise);
  }
  &:hover {
    cursor: pointer;
    path {
      stroke: var(--gold);
    }
  }
}

.prev,
.next {
  composes: button;
}
.next {
  margin-left: auto;
}
.item {
  --pad: 4vw;
  width: 100%;

  &:nth-child(4n + 4) .itemLine {
    background-color: var(--anniversary-purple);
    &:before {
      background-color: var(--turquoise);
    }
  }
  &:nth-child(4n + 2) .itemLine {
    background-color: var(--blue);
    &:before {
      background-color: var(--gold);
    }
    + .itemInner {
      .year:before,
      .year:after {
        background-color: var(--turquoise);
        border-color: var(--turquoise);
      }
    }
  }
  &:nth-child(4n + 3) .itemLine {
    background-color: var(--turquoise);
    &:before {
      background-color: var(--blue);
    }
  }
  &:nth-child(4n + 1) .itemLine {
    background-color: var(--gold);

    &:before {
      background-color: var(--anniversary-purple);
    }
  }

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (min-width: 1024px) {
    width: 33.33%;
  }
}

.itemLine {
  height: 16px;
  position: relative;

  &:before {
    position: absolute;
    z-index: 1;
    content: '';
    height: 16px;
    width: calc(var(--pad) + 0.5rem + 1px);
    top: 0;
    left: -1px;
  }
}

.itemInner {
  padding-right: var(--pad);
  padding-left: var(--pad);
}

.year {
  --color: var(--gold);
  --line-height: calc(1rem + 16px);
  margin: 3rem 0 1rem;

  position: relative;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    bottom: calc(100% + 1rem);
    left: 7px;
    height: var(--line-height);
    border-left: 2px solid var(--color);
  }
  &:after {
    content: '';
    border-radius: 50%;
    background-color: var(--color);
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    bottom: calc(100% + var(--line-height) + 1rem);
  }

  img {
    max-width: 120px;
    display: block;
  }
}
.yearTextVisible {
  @extend %font-h1-sans;
  line-height: 0.8;
  color: var(--blue);
}
