.buttonWrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 1rem;
  margin-top: 2em;
}

.definition {
  --size: 18px;
  font-size: 14px;
  color: var(--blue);
  border: 1px solid;
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}

.allFiltersGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  + .allFiltersGroup:not(.allFiltersGroupDisabled) {
    margin-top: 1em;
  }
}

.allFiltersGroupDisabled {
  composes: allFiltersGroup;
  pointer-events: none;
  opacity: 0.5;
  display: none;
}

.allFiltersGroupHeading {
  font-weight: bold;
  font-size: 14px;
}
