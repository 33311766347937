@import "~scss/imports";

.IconHero {
  text-align: center;
  @extend %marginauto;
  @extend %maxarticle;
}

.IconHero-image {
  max-width: 100px;
  @extend %marginauto;
}

.IconHero-heading {
  @extend %font-h1-sans;
}

.IconHero-explainer {
  @extend %font-body;
}