:root {
  --black: #000;
  --blue: #0377d4;
  --medium-blue: #d7e9f9;
  --light-blue: #eef5fb;
  --yellow: #f9dc32;
  --light-yellow: #fefdf1;
  --white: #fff;
  --green: #2ebbbb;
  --salmon: #f7e1d7;
  --purple: #5c47f5;

  --red: red;

  --anniversary-purple: #6f2cff;
  --gold: #f5b400;
  --turquoise: #02bdbb;

  --border-radius: 1rem;

  --yellow-gradient-start: var(--yellow);
  --yellow-gradient-end: #fcf9dd;

  --nav-height: 80px;
  --max: 1178px;

  --margin-vertical: clamp(2rem, 5vw, 5vw);

  --pad: clamp(1rem, 2vw, 2vw);

  --bigmax: 1600px;
  --maxarticle: 60rem;
  --maxarticlewide: 49rem;
  --maxarticlewidepadding: 1em;
  --article-pad: 1.25rem;
  --article-box-pad: calc(2 * var(--article-pad));
  --maxarticlebox: calc(
    var(--maxarticle) - var(--article-pad) * 2 + var(--article-box-pad) * 2
  );

  --sans: 'Dia', sans-serif;
  --serif: 'Financier', serif;

  --size-h1: 34px;
  --size-h1: 2.125em;
  --line-h1: 1;

  --size-h2: 28px;
  --size-h2: 1.75em;
  --line-h2: 1.22;

  --size-h2-long: 25px;
  --size-h2-long: 1.66em;

  --size-h3: 22px;
  --size-h3: 1.375em;
  --line-h3: 1.22;

  --size-h4: 20px;
  --size-h4: 1.25em;
  --line-h4: 1;

  --size-h5: 14px;
  --size-h5: 0.875em;
  --line-h5: 1;

  --size-bodyfeatured: 20px;
  --size-bodyfeatured: 1.25em;
  --line-bodyfeatured: 1.3;

  --size-body: 18px;
  --size-body: 1.125em;
  --line-body: 1.3;

  --size-bodysmall: 16px;
  --size-bodysmall: 1em;

  --size-callout: 60px;
  --size-callout: 3.75em;
  --line-callout: 1.13;

  --size-cta: 14px;
  --size-cta: 0.875em;
  --line-cta: 1.75;

  --size-iconlabel: 30px;
  --size-iconlabel: 1.875em;
  --line-iconlabel: 1.36;

  --size-fact: 100px;
  --size-fact: 6.25em;

  --size-quick-fact: 16px;
  --size-quick-fact: 1em;

  --size-citation: 12px;
  --size-citation: 0.75em;

  --size-article-h1: 2.125em;
  --line-article-h1: 1.1111;
  --size-article-h2: 2.125em;
  --line-h2: 1.1111;
  --size-article-h3: 1.4em;
  --line-h3: 1.2;
  --size-article-h4: 1.1em;
  --line-h4: 1.16;
  --button-radius: 0.25rem;

  --transition-timing: 0.3s;

  // --filter-width: 275px;
  --filter-width: 250px;
  --dashboard-template-columns: var(--filter-width) 1fr;

  @media (min-width: 768px) {
    --maxarticlewidepadding: 4rem;

    --size-h1: 42px;
    --size-h1: 3em;

    --size-h2: 32px;
    --size-h2: 2em;

    --size-h2-long: 28px;
    --size-h2-long: 1.75em;

    --size-h3: 24px;
    --size-h3: 1.5em;

    --size-cta: 16px;
    --size-cta: 1em;

    --size-body: 19px;
    --size-body: 1.1875rem;

    --size-bodyfeatured: 24px;
    --size-bodyfeatured: 1.5em;

    --size-article-h1: 3em;
    --size-article-h2: 3em;
    --size-article-h3: 1.5em;
    --size-article-h4: 1.25em;
  }

  @media (min-width: 1024px) {
    --filter-width: 290px;
  }

  @media (min-width: 1200px) {
    --filter-width: 332px;
    --size-h1: 48px;
    --size-h1: 3em;

    --size-h2: 36px;
    --size-h2: 2.25em;

    --size-h2-long: 32px;
    --size-h2-long: 2em;

    --size-h3: 28px;
    --size-h3: 1.75em;

    --size-cta: 16px;
    --size-cta: 1em;

    --size-body: 21px;
    --size-body: 1.3125rem;

    --size-article-h1: 3em;
    --size-article-h2: 2.25em;
    --size-article-h3: 1.75em;
    --size-article-h4: 1.5em;
  }

  @media (min-width: 900px) {
    --max: 96%;
  }
}
