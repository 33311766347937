@import '~scss/layout';
@import '~scss/type';

.Accordion {
  background-color: var(--white);
  margin: 1rem;

  @include breakpoint('tablet') {
    margin: 2rem 0;
  }
}

.AccordionItem {
  @extend %max;
  max-width: 1400px;

  & + .AccordionItem .AccordionItem-heading {
    border-top: 1px solid;
  }
}

.AccordionItem-heading {
  align-items: center;
  display: flex;
  background-color: var(--white);
  border: 0;
  padding: 0;
  font-size: 100%;
  width: 100%;
  justify-content: space-between;
  position: sticky;
  text-align: left;
  // top: var(--nav-height);
  top: 0;
  cursor: pointer;
  @extend %font-h2-sans;
  stroke: black;

  .AccordionItem--open & {
    border-top: 1px solid;
    box-shadow: 0 1px 1px -1px var(--black);
  }

  > svg {
    margin-left: 1rem;
    flex-shrink: 0;
  }

  svg * {
    stroke: inherit;
  }

  .AccordionItem--open &,
  &:hover {
    color: var(--blue);
    stroke: var(--blue);
  }

  &:focus {
    outline: 0;
  }
}

.AccordionItem-content {
  padding-top: 1rem;
  padding-bottom: 4rem;
}
