$colors: (
    'black',
    'blue',
    'green',
    'light-blue',
    'purple',
    'red' 'salmon',
    'white',
    'yellow'
);

@each $color in $colors {
    %color--#{$color} {
        color: var(--#{$color});
    }
    %bg--#{$color} {
        background-color: var(--#{$color});
    }
}
