@import '~scss/imports';

.PretrialRelease-bargroup {
  &:hover {
    rect {
      fill-opacity: 0.8;
    }
  }
}

.PretrialRelease-bargroup--selected {
  rect {
    fill-opacity: 0.8;
  }
}

.CAT-results-cta {
  @extend %cta-link;
  align-self: center;
}
