@import "~scss/imports";

.Resources {

}

.ResourcesGroup {
  &+.ResourcesGroup {
    margin-top: 4rem;
  }
}

.ResourcesGroup-heading {
  @extend %font-h3-sans;
  margin-bottom: 2rem;
  border-bottom: 1px solid;
  padding-bottom: .5rem;
}


.ResourcesGroup-list {
  padding: 0;
  margin: 0;
  column-gap: 2rem;

  @include breakpoint('mobile') {
    column-count: 2;
  }

  @include breakpoint('tablet') {
    column-count: 3;
  }

  @include breakpoint('desktop') {
      column-count: 4;
  }
}

.ResourcesGroup-list-item {
  display: block;
  margin-bottom: 1rem;
}

.Resource {
  color: inherit;

  @extend %linkhover;
}