@import "~scss/imports";

.InfoBox {
  @extend %marginauto;
  margin-top: 2em;
  margin-bottom: 2em;
  max-width: var(--maxarticlebox);

  background-color: var(--white);

  padding: var(--article-box-pad) !important;
}

.InfoBox-inner {
  max-width: calc(var(--maxarticle) - var(--article-pad) * 2);
}

.InfoBox-heading {
  @extend %font-h4-sans;
  font-weight: bold;
  color: var(--blue);
}

.InfoBox-text {
  @extend %font-body;
  p:last-child {
    margin-bottom: 0;
  }
}
