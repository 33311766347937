@import '~scss/imports';

.radio {
  // padding: 0.5rem 1rem;
  margin: 0;
  border: 0;
  padding: 0;

  width: 100%;
  min-width: 0;
  // border: 1px solid var(--light-blue);

  > div {
    display: flex;
    flex-direction: column;

    gap: 0.5rem;

    @media (min-width: 600px) {
      display: grid;
      grid-template-columns: calc(var(--filter-width) - 1rem) 1fr;
      gap: 2rem;
      align-items: center;
    }

    > div:last-child {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem 1rem;
    }
  }

  .legend {
    padding: 0;
    margin: 0;
  }

  + .radio {
    legend {
      width: 100%;
    }
  }
}

.radioNoLabel {
  grid-column: 1 /-1;

  > div {
    display: flex;
    align-items: center;
    width: 100%;

    > div {
      width: 100%;
      align-items: center;
      > span:first-child {
        @extend %font-cta;
        width: 100%;

        @media (min-width: 600px) {
          width: auto;
        }
      }
    }
  }
}

.note {
  margin-top: 0.5rem;
  margin-bottom: 0;
  @extend %data-note;
}
.legend {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.radioInput {
  appearance: none;
  height: 0;
  width: 0;
  position: absolute;
  opacity: 0;
}

.radioLabel {
  .radioInput:checked + div {
    background-color: var(--blue);
    color: var(--white);
  }
  div {
    display: flex;
    align-items: center;
    // background-color: transparent;
    // @extend %toggleButton;
    // border: 1px solid var(--blue);

    // @extend %font-h4-sans;
  }
}

.settingsToggle {
  align-self: flex-start;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  padding: 0.25rem;
  margin: 0;
  margin-bottom: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--blue);
  gap: 0.5rem;

  svg {
    flex-shrink: 0;
    display: block;
    width: 12px;
    height: auto;
    transform: rotateY(180deg);

    * {
      stroke: currentColor;
    }
  }
  :global(.CAT-tool-controlshidden) & {
    svg {
      transform: rotateY(0deg);
    }
  }
}
