@import 'breakpoints';

%BlockedHero {
  background-color: var(--light-blue);
  padding: 2rem 1rem;

  @include breakpoint('tablet') {
    padding-top: 50px;
    padding-bottom: 100px;
  }
}
%BlockedHero-inner {
  background-color: var(--white);
}

%max {
  max-width: var(--max);
  margin-left: auto;
  margin-right: auto;
  // padding-left: var(--pad);
  // padding-right: var(--pad);
}

%bigmax {
  max-width: var(--bigmax);
  margin-left: auto;
  margin-right: auto;
}

%maxtext,
%maxarticle {
  max-width: var(--maxarticle);
}

%maxarticlewide {
  max-width: var(--maxarticlewide);
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: var(--maxarticlewidepadding) !important;
  padding-right: var(--maxarticlewidepadding) !important;
}

%maxcallout {
  max-width: 47rem;
}

%maxarticlebox {
  max-width: var(--maxarticlebox);
}

%marginauto {
  margin-left: auto;
  margin-right: auto;
}

%pill {
  padding: 1rem;
  border-radius: 60px;
  @include breakpoint('tablet') {
    padding: 84px;
    border-radius: 600px;
  }
}

%twocolumn {
  display: grid;
  > *:first-child {
    margin-bottom: 2em;
  }
  @include breakpoint('medium') {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    > * {
      align-self: flex-start;
    }
    > *:first-child {
      padding-right: 1em;
      margin-bottom: 0;
    }
    > *:last-child {
      padding-left: 1em;
    }
  }
}

%blockmarginvertical {
  margin-top: var(--margin-vertical);
  margin-bottom: var(--margin-vertical);
}

%min100vh {
  min-height: 100vh;
}

%articleblockpad {
  padding-left: var(--article-pad);
  padding-right: var(--article-pad);
}

%article-box-pad {
  padding-left: var(--article-box-pad);
  padding-right: var(--article-box-pad);
}
