@import "~scss/imports";

.PublicationTable {
  @extend %font-bodysmall;
  border-collapse: collapse;
  overflow: auto;
  width: 100%;
  td {
    padding: 0.5rem;
    border: 2px solid var(--blue);
    text-align: center;
    max-width: 80em;
  }
}

.ReleaseAssessmentResultsTable {
  td {
    min-width: 3em;
  }
}

.PublicationFigure {
  background-color: var(--white);
  padding: var(--article-box-pad);
  overflow: auto;
  max-width: calc(100vw - var(--article-pad) * 2 - 15px);
  figcaption {
    @extend %font-article-h4;
    margin-bottom: 1em;
    color: var(--blue);
  }
}
