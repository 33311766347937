@import '~scss/imports';
// .controls {
//   padding: 0 1em;
// }

:global {
  [id^='data'],
  #about,
  #facts {
    --height: var(--nav-height);
    position: relative;
    pointer-events: none;
    > * {
      pointer-events: auto;
    }
  }
}
.element {
  --gap: clamp(1rem, 2vw, 2vw);
  position: relative;
  min-height: calc(100vh - var(--nav-height));

  :global {
    .CAT {
      max-width: 100%;
      display: grid;
      grid-template-rows: auto 1fr;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;

      gap: var(--gap);

      h1 {
        margin: 0;
        color: var(--blue);
      }
    }
    .CAT,
    .CAT-tool {
      min-height: 400px;
    }

    .IconToggle {
      svg * {
        fill: var(--blue);
      }
    }
  }
}
.element {
  &:global(.CAT--desktop) {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 1em;
    .content {
      position: relative;
    }
    .header {
      text-align: left;
    }

    .dashboardContent {
      display: grid;
      grid-template-rows: auto 1fr;
      gap: 1rem;
      height: calc(100vh - var(--nav-height));
    }
    :global(.CAT) {
      height: 100%;
    }
    :global(.CAT),
    :global(.CAT-tool) {
      width: 100%;
    }
    :global {
      .StackedBars {
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.header {
  background-color: var(--blue);
  color: var(--white);
  z-index: 2;
  position: relative;
}
.headerInner {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 100%;
  min-height: var(--nav-height);
  @extend %max;

  .chartHeading p {
    margin-bottom: 0;
  }

  @media (min-width: 400px) {
    text-align: left;
    flex-direction: row;
    .toggleMenu {
      margin-left: auto;
      margin-right: 1em;
      margin-bottom: 0;
    }
  }
}
.content {
  border-radius: 0.5rem;

  display: grid;
  grid-template-rows: 1fr auto;
}

.heading {
  @extend %font-h2-sans;
  margin: 0;
}
.subheading {
  margin: 0;
  @extend %font-nav;
  text-align: left;
}
.chartHeading {
  padding: 1em;
  > p {
    margin-top: 0;
    margin-bottom: 0.5em;
  }
}

.toggleMenu {
  align-self: center;
  background-color: var(--yellow);
  color: var(--black);
  border: 0;
  margin: 0;
  margin-bottom: 1em;
  border-radius: 3em;
  @extend %font-nav;
  line-height: 1;
  padding: 1em;
  transition: transform 0.3s;

  cursor: pointer;
  &:hover {
    transform: scale(1.03);
  }
}

.switcher {
  position: relative;
}

.switcherMenu {
  display: flex;
  flex-direction: column;
  padding: var(--article-pad);
  @extend %max;

  > p {
    @extend %font-h4-sans;
    font-weight: bold;
    margin: 0;
  }
}
.switcherMenuHeading {
  @extend %font-h1-sans;
}
.switcherMenuText {
  @extend %font-body;
}

.switcherMenuList {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-gap: var(--article-pad);

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}
.switcherMenuButton {
  background-color: transparent;
  text-decoration: none;
  border: 1px solid var(--light-blue);
  border-radius: var(--border-radius);
  width: 100%;
  height: 100%;
  color: inherit;
  text-align: left;
  cursor: pointer;
  position: relative;
  background-color: var(--light-blue);
  padding: var(--pad);
  @extend %font-body;
  transition: background-color 0.3s;
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: auto 1fr;

  &:hover {
    background-color: var(--medium-blue);
  }
}
.currentSwitcherMenuButton {
  composes: switcherMenuButton;
  background-color: var(--white);
  border-color: var(--blue);
  pointer-events: none;
}
.switcherMenuButtonHeading {
  @extend %font-h4-sans;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;

  > svg {
    flex-shrink: 0;
    margin-top: 0.25em;
  }
}
.switcherMenuButtonDescription {
  margin: 0;
}
.switcherMenuButtonCta {
  @extend %font-nav;
  text-transform: uppercase;
}
.controls {
  align-self: center;
  // height: 100%;
  display: flex;
  align-items: flex-end;
  margin-left: 2em;
  height: 100%;
}
.anchor {
  background-color: var(--medium-blue);
  text-decoration: none;
  border-top-left-radius: 1em;
  padding: 0.33em 0.7em;
  + .anchor {
    margin-left: 1em;
  }
}
.toggle {
  background-color: transparent;
  border: 0;
  padding: 1em;
  cursor: pointer;

  .toggle {
    border-left: 1px solid;
  }
}
.activeToggle {
  composes: toggle;
  background-color: var(--light-blue);
}

.about {
  @extend %font-body;
  overflow: auto;
  background-color: var(--light-blue);
  width: 100%;
  p:first-child {
    margin-top: 0;
  }
}
.aboutInner {
  @extend %max;
  padding: calc(2 * var(--pad)) 1em;
  display: grid;
  grid-gap: 3vw;

  section {
    max-width: var(--maxarticle);
  }
  :global(.CAT--desktop) & {
    grid-template-columns: 1fr clamp(250px, 25vw, 350px);
  }
}
.aboutMain {
  section + section {
    margin-top: 2em;
  }
  h2 {
    margin-top: 0;
  }
}

.aboutJump {
  position: relative;
  z-index: 1;

  a {
    text-decoration: none;
    text-transform: uppercase;

    @extend %cta-link;
  }
}

.dashboardWrap {
  @extend %max;
  padding-left: 1rem;
  padding-right: 1rem;

  &:global([id^='data']) {
    position: relative;
    width: 100%;
    padding-top: calc(var(--pad) * 2);
  }

  + .switcherMenu {
    margin-top: 4em;
  }
}

.dashboardContent {
  display: grid;
  grid-gap: 1rem;
}

.facts {
  // width: calc(100% - 2em);
  color: var(--blue);
  background-color: var(--white);
  padding: 1em;
  border: 1px solid;
  border-radius: var(--border-radius);
  // margin-top: calc(var(--size-h2) + var(--size-body));
}
.factHeading {
  margin-top: 0;
  @extend %font-bodysmall;
  font-weight: bold;
}
.factList {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 0.5em;
  @extend %font-bodysmall;

  li + li {
    border-top: 1px solid;
    padding-top: 0.5em;
  }
}

.dataAnchor {
  position: fixed;
  bottom: 2em;
  left: 1rem;
  z-index: 1;
  text-decoration: none;
  @extend %font-cta;
  background-color: var(--white);
  border: 1px solid var(--blue);
  padding: 0.3em 1em;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.title {
  @extend %font-h2-sans;
  color: var(--blue);
  margin: 0;
}

.longTitle {
  composes: title;
  font-size: var(--size-h2-long);
}

.notes {
  padding-left: 1rem;
  padding-right: 1rem;
  @extend %max;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
