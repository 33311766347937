/* autoprefixer grid: autoplace */

.App--lightBlue {
  background-color: var(--light-blue);
}

main {
  display: block;
}

@import '~scss/main.scss';

p a {
  color: inherit;
  @extend %linkhover;
}

.term-tooltip {
  color: var(--blue);
  font-weight: bold;
}
