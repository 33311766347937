@import "~scss/type";
@import "~scss/layout";

.PublicationHero {
  @extend %BlockedHero;
}
.PublicationHero-inner {
  @extend %BlockedHero-inner;
}
.PublicationHero-header {
  background-color: var(--light-yellow);

  .PublicationHero-header-gradient {
    content: "";
    width: 100%;
    height: 16px;
    background-image: linear-gradient(
      to right,
      var(--yellow-gradient-start),
      var(--yellow-gradient-end)
    );
  }
  .PublicationHero-header-inner {
    display: grid;
    grid-template-areas:
      "heading"
      "authors"
      "date"
      "download";
    position: relative;
    padding: 3rem 2rem 50px 2rem;
    grid-gap: 1rem;
    justify-items: center;
    margin: 0 auto;
    text-align: center;
    @include breakpoint("tablet") {
      padding: 36px 100px 50px;
    }
  }
}

.PublicationHero-date {
  grid-area: date;
  @extend %font-body;
}
.PublicationHero-heading {
  @extend %font-h1-serif;
  grid-area: heading;
  margin: 0;
}
.PublicationHero-authors {
  grid-area: authors;
  @extend %font-body-bold;
  // margin-top: 12px;
}
.PublicationHero-download {
  grid-area: download;
  width: 100%;
  display: flex;
  justify-content: center;
  transform: translateY(calc(-50% - 8px));
}
.PublicationHero-download-button {
  background-color: var(--blue);
  color: var(--white);
  @extend %font-cta;
  text-decoration: none;
  padding: 28px 56px;
  border-radius: 56px;
  text-transform: uppercase;
  position: relative;
}

.Publication-main {
  padding-left: 1rem;
  padding-right: 1rem;
}

.Publication-main-padtop {
  padding-top: 4em;
}
