@import "~scss/imports";

.BigStatement {
  position: relative;
  display: grid;
  grid-template-areas:
    "image"
    "description"
    "link";

  .BigStatement-description {
    width: calc(100% - 1em);
    margin-left: auto;
    margin-top: -1em;
  }

  @include breakpoint("tablet") {
    grid-template-areas:
      "image image"
      "link description";
    grid-template-columns: 1fr 2fr;

    .BigStatement-description {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      padding: 1rem 2rem;
    }
  }
  @include breakpoint("desktop") {
    .BigStatement-description {
      padding: 1em 2em;
    }
  }
}

.BigStatement-image {
  grid-area: image;
}

.BigStatement-description {
  grid-area: description;
  @extend %font-h2-serif;
  background-color: var(--blue);
  color: var(--white);
  padding: 1rem;
}

.BigStatement-link {
  grid-area: link;
  text-align: center;

  padding: 2rem 0;

  @include breakpoint("tablet") {
    padding: 4rem 0;
  }
}
.BigStatement-link a {
  @extend %cta-link;
  border-bottom-color: var(--blue);
}
