/* autoprefixer grid: autoplace */

@import '~scss/imports';

.ArticleHero {
  @extend %max;

  display: grid;
  grid-template-areas:
    'heading'
    'image'
    'text'
    'authors'
    'date';
  grid-gap: 1rem;
  grid-template-columns: 1fr;

  .ArticleHero-heading {
    margin-bottom: unset;
  }

  @include breakpoint('tablet') {
    grid-template-areas:
      '. image'
      'date image'
      'heading image'
      'text image'
      'authors image'
      '. image';
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;

    .ArticleHero-authors {
      margin-top: 2rem;
    }
  }
}
.ArticleHero-date,
.ArticleHero-heading,
.ArticleHero-text,
.ArticleHero-authors {
  padding-left: 1rem;
  padding-right: 1rem;
}

.ArticleHero-date {
  grid-area: date;
}
.ArticleHero-heading {
  @extend %font-h1-serif;
  grid-area: heading;
}
.ArticleHero-text {
  @extend %font-body;
  grid-area: text;
}
.ArticleHero-authors {
  grid-area: authors;
  @extend %font-body-bold;
}
.ArticleHero-image {
  grid-area: image;
}

.ArticleHero + .Article {
  margin-top: 2rem;
}

.Article {
  padding-left: 1em;
  padding-right: 1em;
}
.Article > * {
  @extend %blockmarginvertical;
}
.Article > *:first-child {
  margin-top: 0;
}

.Publication-main,
.Article {
  > .Text,
  > .Fact,
  > .Callout,
  > .InfoBox,
  > .Code,
  > .Accordion,
  > .ReadMore {
    @extend %articleblockpad;
  }
}

.Article .AccordionItem {
  @extend %maxarticlebox;
  .AccordionItem-heading {
    @extend %article-box-pad;
  }
}

.Article .Image {
  @extend %max;
  @extend %marginauto;

  &.Image--textColumn {
    @extend %maxarticle;
  }
}
.Article .Image figcaption {
  margin-top: 1rem;
}

.Article-related {
  @extend %bg--light-blue;
  margin-bottom: 0;

  .Heading {
    text-align: center;
    @extend %font-h2-sans;
  }
  .Stream {
    margin-top: 48px;
  }
}
