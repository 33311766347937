.logo {
}

.flatLogo {
  display: none;
}

:global(.App--blue) {
  .logo {
    display: none;
  }
  .flatLogo {
    display: block;
  }
}
