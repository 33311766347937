@import "~scss/imports";

.ContactLinks {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: grid;
  grid-gap: 4rem;

  @include breakpoint('tablet') {
    grid-template-columns: repeat(2, 1fr);
  }
}
.ContactLink {
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 1fr;
}
.ContactLink-label {
  text-align: center;
}

.ContactLink-heading {
  @extend %font-h3-sans;
}
.ContactLink-description {
  @extend %font-body;
  margin-top: 1rem;
}
.ContactLink-link {
  @extend %font-body-bold;
  @extend %linkhover;
  align-items: center;
  color: inherit;
  display: flex;
  text-decoration: none;
  margin-top: 2rem;
  align-self: end;

  svg {
    fill: var(--black);
    margin-right: .5rem;
  }
}