@import "~scss/layout";
@import "~scss/type";

.Search {
  width: 100%;
  z-index: 1;
  @extend %max;
  display: flex;
  flex-direction: column;
  min-height: calc(60vh - var(--nav-height));

  .StreamResults {
    margin-top: 2rem;
  }
}

.Search-close {
  display: block;
  margin-left: auto;
  width: 100px;
  height: 100px;
}

.Search-inputs {
  display: flex;
  justify-content: center;
  padding: 1em;
  position: sticky;
  top: var(--nav-height);
  z-index: 1;
  label {
    width: 100%;
    max-width: 600px;
  }
}

.Search-inputs-query {
  align-items: center;
  display: flex;
  border-bottom: 2px solid;
  color: var(--blue);
}
.Search-inputs-query-input {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  color: inherit;
  margin-left: 1rem;
  width: 100%;
  @extend %font-h3-serif;
  &:focus {
    outline: 0;
  }
}
