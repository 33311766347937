@import "~scss/color";
@import "~scss/type";
@import "~scss/layout";

%footer-heading {
  @extend %font-cta;
  text-transform: uppercase;
  border-bottom: 1px solid;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.Footer {
  display: grid;
  grid-template-areas:
    "resources"
    "contact"
    "newsletter"
    "links"
    "copyright"
  ;
  padding: 1rem;

  @include breakpoint('tablet') {
    grid-template-areas:
      "resources resources resources"
      "contact newsletter links"
      "copyright copyright copyright"
    ;
    grid-template-columns: 2fr 1fr 1fr;
    grid-column-gap: 2rem;
    padding: 2rem;
  }
}

.Footer-resources {
  grid-area: resources;
  text-align: center;
  @extend %blockmarginvertical;
}
.Footer-resources-heading {
  @extend %font-h2-sans-bold;
}
.Footer-resources-explainer {
  margin-top: 1em;
}
.Footer-resources-list {
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 3rem 0 0;
  display: flex;
  flex-direction: column;

  @include breakpoint('tablet') {
    flex-direction: row;
    justify-content: center;
  }
}
.Footer-resources-list-item {
  margin: 1rem;

}
.Footer-resources-list-item a {
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;
  @extend %font-cta;
  @extend %linkhover;

  svg {
    display: block;
    margin-right: .5rem;
    * {
      fill: var(--white);
    }
  }
}

.Footer-contact {
  grid-area: contact;
  line-height: 1.375;
}

.Footer-contact-logo {

}

.Footer-contact-address {
  margin-top: 1rem;
}

.Footer-contact-phone {
  margin-top: 1rem;
}

.Footer-newsletter {
  grid-area: newsletter;
}

.Footer-newsletter-heading {
  @extend %footer-heading;
}

.Footer-links {
  grid-area: links;
}

.Footer-links-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  column-count: 2;
}

.Footer-links-heading {
  @extend %footer-heading;
}

.Footer-links-list-item {
  margin-bottom: 1em;
}

.Footer-links-list-item a {
  color: inherit;
  text-decoration: none;

  @extend %linkhover;
}


.Footer-copyright {
  grid-area: copyright;
  text-align: center;
  margin-top: 100px;
}


.NewsletterSignup form {
  display: flex;
}


.NewsletterSignup-email {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  input {
    background-color: transparent;
    appearance: none;
    border: 1px solid;
    border-right: 0;
    color: inherit;
    font-size: 100%;
    padding: .5rem;
    height: 48px;

    &::placeholder {
      color: var(--white);
    }

    &:focus {
      outline: 0;
      border-color: var(--white);
      background-color: var(--white);
      color: var(--blue);
    }
  }
}

.NewsletterSignup-email-label {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
}

.NewsletterSignup-submit {
  align-self: flex-end;
  appearance: none;
  background-color: transparent;
  border: 1px solid;
  color: inherit;
  height: 48px;
  width: 36px;
  position: relative;

  &:after {
    border-right: 2px solid;
    border-bottom: 2px solid;
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    left: 7px;
    top: calc(50% - 6px);
    transform: rotate(-45deg);
  }
}

.NewsletterSignup-message {
  padding: .5em;
  a {
    color: inherit;
  }
}