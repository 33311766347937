.Carousel {
  position: relative;
  margin-top: var(--margin-vertical);
  @extend %bigmax;

  .Heading {
    @extend %font-h2-sans-bold;
    text-align: center;
  }
}

.Carousel .swiper {
  max-width: 100vw;
  padding: 0px 50px;

  @include breakpoint("tablet") {
    padding: 50px 120px;
  }
}

.Carousel {
  .swiper-button-next,
  .swiper-button-prev {
    cursor: pointer;
    position: absolute;
    z-index: 1000;
    box-sizing: content-box;
    padding: 10px;
    top: 50%;
    margin-top: -15px;
    right: 0;
    width: 40px;
    height: 40px;
    box-sizing: border-box;

    @include breakpoint("tablet") {
      margin-right: 44px;
    }
  }

  .swiper-button-prev {
    left: 0;
    transform: rotate(180deg);

    @include breakpoint("tablet") {
      margin-left: 44px;
    }
  }

  &.start .swiper-button-prev {
    cursor: default;
    opacity: 0.5;
  }

  &.end .swiper-button-next {
    cursor: default;
    opacity: 0.5;
  }
}
