@import "~scss/imports";

.ArticleFeaturedContent {
  @extend %bg--light-blue;
  @extend %maxarticlewide;
  @extend %marginauto;
}
.ArticleFeaturedContent-inner {
  display: grid;
  grid-template-areas: "heading" "text" "post";
  grid-gap: 1em;
  @extend %marginauto;
  @include breakpoint("tablet") {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "heading post"
      "text post";
  }
}

.ArticleFeaturedContent-heading {
  grid-area: heading;
  @extend %font-h2-serif;
}

.ArticleFeaturedContent-text {
  grid-area: text;
  @extend %font-body;
}

.ArticleFeaturedContent-post {
  grid-area: post;
}
